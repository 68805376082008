import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Select,
  useDisclosure,
} from '@chakra-ui/react';
import { useTurma } from 'contexts/turma';
import React, { useState } from 'react';
import { FaReply, FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import BoxContent from '../../components/BoxContent';
import DataTable, { IColumns } from '../../components/DataTable';
import TituloPagina from '../../components/TituloPagina';
import api from '../../services/api';
import ModalRevalidarInscricao from './ModalRevalidarInscricao';

interface IFields {
  [key: string]: string | number;
}

const Inscricoes: React.FC = () => {
  const {
    turmasPessoaComissao,
    turmaSelecionada,
    selecionaTurma,
    loadInscricao,
  } = useTurma();
  const history = useHistory();
  const [inscricaoSelecionada, setInscricaoSelecionada] = useState<
    number | undefined
  >();
  const {
    isOpen: isOpenRevalidarInscricao,
    onOpen: onOpenRevalidarInscricao,
    onClose: onCloseRevalidarInscricao,
  } = useDisclosure();

  const colunas: IColumns = [
    {
      field: 'pes_codigo',
      text: 'Matrícula',
      type: {
        name: 'text',
      },
    },
    {
      field: 'gra_sigla',
      text: 'Post./Grad.',
      type: {
        name: 'text',
      },
    },
    {
      field: 'pes_nome',
      text: 'Nome',
      type: {
        name: 'text',
      },
    },
    {
      field: 'uni_sigla',
      text: 'Opm',
      type: {
        name: 'text',
      },
    },

    {
      field: 'numero_inscricao',
      text: 'Inscrição',
      type: {
        name: 'text',
      },
    },

    {
      field: 'status',
      text: 'Situação',
      type: {
        name: 'text',
      },
    },
  ];

  function handleClickRevalidar(row: IFields): void {
    setInscricaoSelecionada(Number(row.id_inscricao));
    onOpenRevalidarInscricao();
  }

  async function handleClickVisualizar(row: IFields): Promise<void> {
    await loadInscricao(Number(row.id_inscricao));
    history.push('/inscricao');
  }

  const options = {
    serverData: {
      url: `/inscricoes`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `turma=${turmaSelecionada}`,
    },
    actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <FaSearch size={13} />,
          tooltip: 'Visualizar',
          getRow: handleClickVisualizar,
        },
        {
          icon: <FaReply size={13} />,
          tooltip: 'Revalidar',
          getRow: handleClickRevalidar,
        },
      ],
    },
    filters: [
      {
        field: 'status',
        label: 'Situação',
        options: [
          { value: 'todos', label: 'TODOS' },
          { value: 'pendente', label: 'PENDENTE' },
          { value: 'deferido', label: 'DEFERIDO' },
          { value: 'indeferido', label: 'INDEFERIDO' },
        ],
        defaultOption: 'TODOS',
      },
    ],
    search: {
      searchable: true,
      label: 'Pesquisar:',
      fields: ['pes_codigo', 'gra_sigla', 'pes_nome'],
    },
    exportCsv: {
      visible: true,
      label: 'Baixar planilha',
      columns: [
        { field: 'pes_codigo', title: 'Matricula' },
        { field: 'numero_inscricao', title: 'Nº Inscricao' },
        { field: 'pm_cpf', title: 'CPF' },
        { field: 'pes_nome', title: 'Nome' },
        { field: 'gra_sigla', title: 'Post/Grad' },
        { field: 'uni_sigla', title: 'OPM' },
        { field: 'uni_superior', title: 'OPM Superior' },
        { field: 'uni_gra_cmdo', title: 'Grande Comando' },
        { field: 'bairro', title: 'Bairro' },
        { field: 'cep', title: 'CEP' },
        { field: 'telefone', title: 'Telefone' },
        { field: 'email', title: 'Email' },
        { field: 'endereco', title: 'Endereco' },
        { field: 'cidade', title: 'Cidade' },
        { field: 'dados_complementares', title: 'Dados Complementares' },
        { field: 'data_inscricao', title: 'Data Inscricao' },
        { field: 'data_validacaofield', title: 'Data Validação' },
        { field: 'id_inscricao', title: 'Cod Inscricao' },
        { field: 'id_turma', title: 'Cod Turma' },
        { field: 'local_prova', title: 'Local Prova' },
        { field: 'motivo_indeferido', title: 'Motivo Indeferido' },
        { field: 'status', title: 'Status' },
        { field: 'uf', title: 'Estado' },
        { field: 'pm_data_nasc', title: 'Data Nascimento' },
        { field: 'pm_sexo', title: 'Sexo' },
        { field: 'spm_nome', title: 'Situação Funcional' },
      ],
      filename: `inscricoes-sgi-turma:${turmaSelecionada}`,
    },
  };

  return (
    <>
      <TituloPagina title="Relação Geral de Inscritos" />
      <BoxContent>
        <HStack>
          <FormControl id="turma">
            <FormLabel>Turma:</FormLabel>
            <Select
              value={turmaSelecionada}
              onChange={(e) => selecionaTurma(Number(e.target.value))}
            >
              <option value={0}>Selecione uma Turma</option>
              {turmasPessoaComissao &&
                turmasPessoaComissao.map((turmaPessoa) => (
                  <option
                    key={turmaPessoa.id_turma}
                    value={turmaPessoa.id_turma}
                  >
                    {turmaPessoa.turma.tipo_turma.nome} -{' '}
                    {turmaPessoa.turma.titulo}
                  </option>
                ))}
            </Select>
          </FormControl>
        </HStack>
        {turmaSelecionada > 0 && (
          <Box maxWidth="calc(100vw - 50px)">
            <DataTable columns={colunas} options={options} />
          </Box>
        )}
        {isOpenRevalidarInscricao && inscricaoSelecionada && (
          <ModalRevalidarInscricao
            isOpen={isOpenRevalidarInscricao}
            onClose={onCloseRevalidarInscricao}
            id_inscricao={inscricaoSelecionada}
          />
        )}
      </BoxContent>
    </>
  );
};

export default Inscricoes;
