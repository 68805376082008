import React, { useMemo } from 'react';

import { format } from 'date-fns';
import parse from 'html-react-parser';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { useTurma } from 'contexts/turma';
import BoxContent from '../../components/BoxContent';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import Accordion from '../../components/Accordion';

import TableAnexosInscrito from './TableAnexosInscrito';
import DadosComplementares from './DadosComplementares';

const Label: React.FC = ({ children }) => {
  return (
    <Text fontWeight="600" marginBottom="1px">
      {children}
    </Text>
  );
};

const Inscricao: React.FC = () => {
  const { user } = useAuth();
  const { inscricao } = useTurma();

  const alertaStatus = useMemo(() => {
    switch (inscricao?.status) {
      case 'pendente':
        return 'warning';
      case 'deferido':
        return 'success';
      case 'indeferido':
        return 'error';

      default:
        return 'error';
    }
  }, [inscricao]);

  const alertaTitulo = useMemo(() => {
    switch (inscricao?.status) {
      case 'pendente':
        return 'Aguarde!';
      case 'deferido':
        return 'Parabéns!';
      case 'indeferido':
        return 'Indeferido';

      default:
        return 'error';
    }
  }, [inscricao]);

  const alertaTexto = useMemo(() => {
    switch (inscricao?.status) {
      case 'pendente':
        return 'Sua inscrição foi feita com sucesso, aguarde a análise dos dados pela comissão do processo seletivo.';
      case 'deferido':
        return 'Sua inscrição foi deferida com sucesso!';
      case 'indeferido':
        return 'Sua inscrição foi indeferida!';

      default:
        return 'error';
    }
  }, [inscricao]);

  const statusInscricao = useMemo(() => {
    switch (inscricao?.status) {
      case 'pendente':
        return 'INSCRITO - AGUARDANDO VALIDAÇÃO';
      case 'deferido':
        return 'DEFERIDO';
      case 'indeferido':
        return 'INDEFERIDO';

      default:
        return 'error';
    }
  }, [inscricao]);

  const formattedDate = useMemo(() => {
    if (inscricao) {
      return format(
        new Date(inscricao?.data_inscricao),
        "dd/MM/yyyy', às ' HH'h':mm'min'",
      );
    }
    return '';
  }, [inscricao]);

  return (
    <>
      <TituloPagina
        title={
          inscricao?.turma?.tipo_turma.tipo === 'consulta'
            ? 'Formulário'
            : 'Dados do Processo Seletivo'
        }
      />

      <BoxContent>
        {inscricao && (
          <Box>
            {inscricao?.turma?.tipo_turma.tipo !== 'consulta' &&
              inscricao.pes_codigo === user.matricula && (
                <Alert status={alertaStatus} borderRadius="8px" mb="16px">
                  <AlertIcon />
                  <Box flex="1">
                    <AlertTitle>{alertaTitulo}</AlertTitle>
                    <AlertDescription display="block">
                      {alertaTexto}
                    </AlertDescription>
                  </Box>
                </Alert>
              )}

            {inscricao.turma?.tipo_turma.tipo !== 'consulta' && (
              <Accordion
                defaultIndex={[0]}
                label="Dados do Processo Seletivo"
                mt="2"
              >
                <Flex direction="column" fontSize="0.90rem">
                  <Flex direction="row">
                    <Text fontWeight="600" mr="8px">
                      Número de Inscrição:
                    </Text>
                    <Text>{inscricao.numero_inscricao}</Text>
                  </Flex>
                  <Flex direction="row">
                    <Text fontWeight="600" mr="8px">
                      Data da Inscrição:
                    </Text>

                    <Text>{formattedDate}</Text>
                  </Flex>
                  <Flex direction="row">
                    <Text fontWeight="600" mr="8px">
                      Validação:
                    </Text>
                    <Text>{statusInscricao}</Text>
                  </Flex>
                  {inscricao && inscricao.turma.locais_provas.length > 0 && (
                    <Flex direction="row">
                      <Text fontWeight="600" mr="8px">
                        Polo selecionado:
                      </Text>
                      <Text>{inscricao.local_prova}</Text>
                    </Flex>
                  )}
                  {inscricao.status === 'indeferido' && (
                    <Flex direction="row">
                      <Text fontWeight="600" mr="8px">
                        Motivo do Indeferimento:
                      </Text>
                      <Text>{inscricao.motivo_indeferido}</Text>
                    </Flex>
                  )}
                </Flex>
              </Accordion>
            )}

            <Accordion
              defaultIndex={[0]}
              label={
                inscricao.turma.tipo_turma.tipo === 'consulta'
                  ? 'Dados da consulta'
                  : 'Dados do Processo Seletivo'
              }
              mt="2"
            >
              <Flex direction="column" fontSize="0.90rem">
                <Flex direction="row">
                  <Text fontWeight="600" mr="8px">
                    Nome:
                  </Text>
                  <Text>
                    {inscricao.turma.tipo_turma.nome}-{inscricao.turma.titulo}
                  </Text>
                </Flex>
                <Flex direction="row">
                  <Text fontWeight="600" mr="8px">
                    {inscricao.turma.tipo_turma.tipo === 'consulta'
                      ? 'Período Inicial da Consulta:'
                      : 'Data Início das Inscrições:'}
                  </Text>
                  <Text>
                    {new Date(
                      inscricao.turma.data_inicio_inscricao,
                    ).toLocaleDateString('pt-BR')}
                  </Text>
                </Flex>
                <Flex direction="row">
                  <Text fontWeight="600" mr="8px">
                    {inscricao.turma.tipo_turma.tipo === 'consulta'
                      ? 'Período Final da Consulta:'
                      : 'Data Fim das Inscrições:'}
                  </Text>
                  <Text>
                    {new Date(
                      inscricao.turma.data_fim_inscricao,
                    ).toLocaleDateString('pt-BR')}
                  </Text>
                </Flex>
                {inscricao.turma.instrucoes && (
                  <Flex direction="row">
                    <Text fontWeight="600" mr="8px">
                      Informações Adicionais:
                    </Text>
                    <Text>{parse(inscricao.turma.instrucoes)}</Text>
                  </Flex>
                )}
              </Flex>
            </Accordion>
            {inscricao.turma.publicacoes.length > 0 && (
              <Accordion defaultIndex={[0]} label="Publicações" mt="2">
                <UnorderedList>
                  {inscricao.turma.publicacoes.map((publicacao) => (
                    <ListItem key={publicacao.id_publicacao}>
                      <Link
                        color="#3182ce"
                        href={`${process.env.REACT_APP_URL_API}/files/${publicacao.filename}.${publicacao.ext}`}
                        isExternal
                      >
                        {publicacao.titulo}
                      </Link>
                    </ListItem>
                  ))}
                </UnorderedList>
              </Accordion>
            )}
            <Accordion defaultIndex={[0]} label="Dados do PM" mt="2">
              <Box display="flex" flexDirection="column" fontSize="0.90rem">
                <Box>
                  <Label>Nome:</Label>
                  <span>{inscricao.pessoa.pessoa.pes_nome}</span>
                </Box>

                <Box>
                  <Label>Posto/Grad.:</Label>
                  <span>{inscricao.pessoa.graduacao.gra_nome}</span>
                </Box>
                <Flex
                  direction={{
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                    xl: 'row',
                  }}
                >
                  {inscricao.pessoa.graduacao.gra_codigo >= 12 && (
                    <Box marginRight="16px">
                      <Label>Número:</Label>
                      <span>{inscricao.pessoa.pm_numero}</span>
                    </Box>
                  )}
                  <Box>
                    <Label>Matrícula:</Label>
                    <span>{inscricao.pes_codigo}</span>
                  </Box>
                </Flex>
              </Box>

              <Box marginLeft={{ base: '8px', md: '0px' }} fontSize="0.90rem">
                <Label>Opm:</Label>
                <Text>{`${inscricao.pessoa.opm.uni_nome} - ${inscricao.pessoa.opm.uni_sigla}`}</Text>
              </Box>
            </Accordion>
            {inscricao &&
              inscricao.documentos &&
              inscricao.documentos.length > 0 && (
                <Accordion
                  defaultIndex={[0]}
                  label="Documentos Anexados"
                  mt="2"
                >
                  <TableAnexosInscrito
                    documentos={inscricao ? inscricao.documentos : []}
                  />
                </Accordion>
              )}
            {inscricao.campos_extras.length > 0 && <DadosComplementares />}
          </Box>
        )}
      </BoxContent>
    </>
  );
};

export default Inscricao;
