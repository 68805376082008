import React, { useCallback, useState } from 'react';
import { MdCheck, MdSearch } from 'react-icons/md';
import { Box, Tooltip, useDisclosure } from '@chakra-ui/react';
import { Table } from './styles';
import api from '../../../services/api';
import ModalValidaDocumento from '../ModalValidaDocumento';
import ModalValidaDocumentoDispensado from '../ModalValidaDocumentoDispensado';

interface IDocumento {
  id_documento_inscricao: number | null;
  id_inscricao: number;
  id_documento_turma: number;
  valido: boolean | null;
  motivo_invalido: string | null;
  dispensado: boolean;
  filename: string | null;
  ext: string | null;

  documento: {
    nome: string;
  };
}

interface IProps {
  documentos: IDocumento[];
}

const AnexosTableInscrito: React.FC<IProps> = ({ documentos }) => {
  const {
    isOpen: isOpenValidaDocumento,
    onOpen: onOpenValidaDocumento,
    onClose: onCloseValidaDocumento,
  } = useDisclosure();

  const {
    isOpen: isOpenValidaDocumentoDispensado,
    onOpen: onOpenValidaDocumentoDispensado,
    onClose: onCloseValidaDocumentoDispensado,
  } = useDisclosure();

  const [idDocumento, setIdDocumento] = useState<number>();
  const [documentoDispensado, setDocumentoDispensado] = useState<any>(
    undefined,
  );

  const valido = useCallback((value: boolean | null) => {
    switch (value) {
      case true:
        return 'SIM';
      case false:
        return 'NÃO';

      default:
        return '';
    }
  }, []);

  return (
    <Box
      overflowX="auto"
      overflowY="hidden"
      whiteSpace="nowrap"
      maxWidth="100%"
      css={{
        '&::-webkit-scrollbar': {
          width: '4px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#ccc',
          borderRadius: '24px',
        },
      }}
    >
      <Box maxWidth={{ sm: '280px', md: '100%', lg: '100%', xl: '100%' }}>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Documento</th>
              <th>Dispensado?</th>
              <th>Válido?</th>
              <th className="actions" style={{ textAlign: 'center' }}>
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            {documentos &&
              documentos.map((documento, index) => (
                <tr key={documento.id_documento_turma}>
                  <td>{index + 1}</td>
                  <td>{documento.documento.nome} </td>
                  <td>{documento.dispensado ? 'SIM' : ''} </td>
                  <td>{valido(documento.valido)} </td>

                  <td className="actions">
                    {!documento.dispensado && (
                      <Tooltip label="Ver Arquivo" hasArrow placement="left">
                        <Box>
                          <MdSearch
                            size={20}
                            color={documento.valido !== null ? 'green' : 'gray'}
                            cursor="pointer"
                            onClick={() => {
                              // window.open(
                              //   `${process.env.REACT_APP_URL_API}/documentos/${documento.filename}.${documento.ext}`,
                              // );

                              const loadFile = async (): Promise<void> => {
                                const response = await api({
                                  url: `documentos/${documento.filename}.${documento.ext}`,
                                  method: 'GET',
                                  responseType: 'blob',
                                });

                                const url = window.URL.createObjectURL(
                                  new Blob([response.data], {
                                    type: response.data.type,
                                  }),
                                );
                                const link = document.createElement('a');
                                link.href = url;
                                link.target = '_blank';
                                link.click();
                              };
                              if (!documento.dispensado) {
                                loadFile();
                              }
                            }}
                          />
                        </Box>
                      </Tooltip>
                    )}
                    <Tooltip label="Validar" hasArrow placement="left">
                      <Box>
                        <MdCheck
                          size={20}
                          color={documento.valido !== null ? 'green' : 'gray'}
                          cursor="pointer"
                          onClick={() => {
                            if (documento.id_documento_inscricao) {
                              onOpenValidaDocumento();
                              setIdDocumento(documento.id_documento_inscricao);
                            } else {
                              setDocumentoDispensado(documento);
                              onOpenValidaDocumentoDispensado();
                            }
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        {idDocumento && (
          <ModalValidaDocumento
            isOpen={isOpenValidaDocumento}
            onClose={onCloseValidaDocumento}
            id_documento_inscricao={idDocumento}
          />
        )}

        {documentoDispensado && (
          <ModalValidaDocumentoDispensado
            isOpen={isOpenValidaDocumentoDispensado}
            onClose={onCloseValidaDocumentoDispensado}
            documento={documentoDispensado}
          />
        )}
      </Box>
    </Box>
  );
};

export default AnexosTableInscrito;
