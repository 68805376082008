import React, { useCallback, useEffect } from 'react';
import { Box, Heading, SimpleGrid } from '@chakra-ui/react';

import { format } from 'date-fns';
import BoxContent from '../../components/BoxContent';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { useTurma } from '../../contexts/turma';
import TurmaBox from './TurmaBox';

import TableEncerradas from './TableEncerradas';

const Home: React.FC = () => {
  const { user } = useAuth();
  const {
    loadTurmas,
    loadInscricoesUsuario,
    turmas,
    loadTurmasPessoaComissao,
  } = useTurma();

  useEffect(() => {
    const carregarTurmas = async (): Promise<void> => {
      try {
        const promises = [
          loadTurmas(),
          loadInscricoesUsuario(user.matricula),
          loadTurmasPessoaComissao(user.matricula),
        ];
        await Promise.all(promises);
      } catch (error) {
        console.log('Ocorreu um erro');
      }
    };
    carregarTurmas();
  }, [loadTurmas, loadInscricoesUsuario, loadTurmasPessoaComissao, user]);

  const formattedDate = useCallback((data: string) => {
    return format(new Date(data), 'dd/MM/yyyy');
  }, []);

  return (
    <>
      <TituloPagina title="Painel principal" />
      <BoxContent>
        <Box maxWidth="calc(100vw - 50px)">
          <Heading as="h3" size="sm" mb="8px">
            Cursos, Instruções e Concursos com inscrições abertas
          </Heading>
          <Box
            borderRadius="4px"
            p={['12px', '16px']}
            border="1px solid #ddd"
            minHeight="134px"
            mb="16px"
            background="#f5f5f5"
          >
            <SimpleGrid
              minChildWidth="160px"
              spacing={2}
              width={turmas?.abertas?.length <= 2 ? '380px' : '100%'}
            >
              {turmas.abertas &&
                turmas.abertas.length > 0 &&
                turmas.abertas.map((turma) => (
                  <TurmaBox
                    key={turma.id_turma}
                    id={turma.id_turma}
                    inicio={formattedDate(turma.data_inicio_inscricao)}
                    fim={formattedDate(turma.data_fim_inscricao)}
                    aberto
                    titulo={turma.titulo}
                    criterios={turma.criterios}
                    filename={
                      turma.tipo_turma.logo_filename
                        ? `${turma.tipo_turma.logo_filename}.${turma.tipo_turma.logo_ext}`
                        : undefined
                    }
                  />
                ))}
            </SimpleGrid>
          </Box>

          <Heading as="h3" size="sm" mb="8px">
            Cursos, Instruções e Concursos com inscrições previstas
          </Heading>
          <Box
            borderRadius="4px"
            p="16px"
            border="1px solid #ddd"
            minHeight="134px"
            mb="16px"
            background="#f5f5f5"
          >
            <SimpleGrid
              minChildWidth="160px"
              spacing={2}
              width={turmas?.abertas?.length <= 2 ? '380px' : '100%'}
            >
              {turmas.previstas &&
                turmas.previstas.length > 0 &&
                turmas.previstas.map((turma) => (
                  <TurmaBox
                    key={turma.id_turma}
                    id={turma.id_turma}
                    inicio={formattedDate(turma.data_inicio_inscricao)}
                    fim={formattedDate(turma.data_fim_inscricao)}
                    aberto={false}
                    titulo={turma.titulo}
                    filename={
                      turma.tipo_turma.logo_filename
                        ? `${turma.tipo_turma.logo_filename}.${turma.tipo_turma.logo_ext}`
                        : undefined
                    }
                  />
                ))}
            </SimpleGrid>
          </Box>

          <Heading as="h3" size="sm" mb="8px">
            Cursos, Instruções e Concursos com inscrições encerradas
          </Heading>

          <TableEncerradas inscricoes={turmas.encerradas} />
        </Box>
      </BoxContent>
    </>
  );
};

export default Home;
