import React, { useCallback } from 'react';
import { MdSearch } from 'react-icons/md';

import { Box, Tooltip } from '@chakra-ui/react';

import { Table } from './styles';
import api from '../../../services/api';

interface IDocumento {
  id_documento_inscricao: number;
  id_inscricao: number;
  id_documento_turma: number;
  dispensado: boolean;
  filename: string;
  ext: string;

  documento: {
    id_documento_turma: number;
    id_turma: number;
    nome: string;
    slug: string;
    tipo_upload: string;
    descricao: string;
  };
}

interface IProps {
  documentos: IDocumento[];
}

const AnexosTableInscrito: React.FC<IProps> = ({ documentos }) => {
  const tipoUpload = useCallback((tipo_upload: string): {
    label: string;
    value: string;
  } => {
    switch (tipo_upload) {
      case '1':
        return { label: 'PDF', value: '.pdf' };
      case '2':
        return { label: 'IMAGEM', value: '.jpg, .jpeg, .png' };
      case '3':
        return { label: 'PDF/IMAGE', value: '.jpg, .jpeg, .png, .pdf' };

      default:
        return { label: 'PDF/IMAGE', value: '.jpg, .jpeg, .png, .pdf' };
    }
  }, []);

  return (
    <Box
      overflowX="auto"
      overflowY="hidden"
      whiteSpace="nowrap"
      maxWidth="100%"
      css={{
        '&::-webkit-scrollbar': {
          width: '4px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#ccc',
          borderRadius: '24px',
        },
      }}
    >
      <Box maxWidth={{ sm: '280px', md: '100%', lg: '100%', xl: '100%' }}>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Documento</th>
              <th className="actions" style={{ textAlign: 'center' }}>
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            {documentos &&
              documentos.map((documento, index) => (
                <tr key={documento.id_documento_turma}>
                  <td>{index + 1}</td>
                  <td>{documento.documento.nome} </td>

                  <td className="actions">
                    <Tooltip label="Ver Arquivo" hasArrow placement="left">
                      <Box>
                        <MdSearch
                          size={20}
                          color="green"
                          cursor="pointer"
                          onClick={() => {
                            // window.open(
                            //   `${process.env.REACT_APP_URL_API}/documentos/${documento.filename}.${documento.ext}`,
                            // );

                            const loadFile = async (): Promise<void> => {
                              const response = await api({
                                url: `documentos/${documento.filename}.${documento.ext}`,
                                method: 'GET',
                                responseType: 'blob',
                              });

                              const url = window.URL.createObjectURL(
                                new Blob([response.data], {
                                  type: response.data.type,
                                }),
                              );
                              const link = document.createElement('a');
                              link.href = url;
                              link.target = '_blank';
                              link.click();
                            };

                            loadFile();
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default AnexosTableInscrito;
