import React from 'react';
import parse from 'html-react-parser';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  texto: string;
}

const ModalMotivoDispensa: React.FC<IProps> = ({
  isOpen,
  onClose,
  texto = 'Não especificado',
}) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Motivo de dispensa</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>{parse(texto)}</ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Fechar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalMotivoDispensa;
