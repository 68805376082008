import React from 'react';

import { AuthProvider } from './auth';
import { TurmaProvider } from './turma';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <TurmaProvider>{children}</TurmaProvider>
  </AuthProvider>
);

export default AppProvider;
