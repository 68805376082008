import React, { useCallback, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';

import { useTurma } from 'contexts/turma';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  id_inscricao: number;
}

const ModalDeferir: React.FC<IProps> = ({ isOpen, onClose, id_inscricao }) => {
  const { validaInscricao } = useTurma();
  const [isLoading, setIsLoading] = useState(false);

  const handleClickButton = useCallback(async () => {
    setIsLoading(true);
    await validaInscricao({
      id_inscricao,
      status: 'deferido',
    });
    setIsLoading(false);
    onClose();
  }, [id_inscricao, validaInscricao, onClose]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Deferir Inscrição</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          Você tem certeza que deseja DEFERIR a inscrição do candidato?
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="primary"
            mr={3}
            onClick={handleClickButton}
            isLoading={isLoading}
          >
            Sim
          </Button>
          <Button colorScheme="red" onClick={onClose}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalDeferir;
