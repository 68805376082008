import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import parse from 'html-react-parser';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  ListItem,
  Select,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useTurma } from 'contexts/turma';
import api from 'services/api';
import BoxContent from '../../components/BoxContent';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import Accordion from '../../components/Accordion';
import TableAnexos from './TableAnexos';
import ModalConfirmaInscricao from './ModalConfirmaInscricao';
import DadosComplementares from './DadosComplementares';

const Label: React.FC = ({ children }) => {
  return (
    <Text fontWeight="600" marginBottom="1px">
      {children}
    </Text>
  );
};

type Inputs = {
  endereco: string;
  cidade: string;
  bairro: string;
  uf: string;
  cep: string;
  telefone: string;
  local_prova: string;
};

type CampoExtraValor = {
  id_campo_extra_turma: number;
  valor: string;
};

type Endereco = {
  pes_codigo_endereco: number;
  pes_cep: string;
  pes_endereco: string;
  pes_endereco_num: string;
  pes_endereco_complemento: string;
  pes_bairro: string;
  pes_cidade: string;
  pes_estado: string;
};

const Inscrever: React.FC = () => {
  const [dados, setDados] = useState<any>({});
  const [camposExtrasValores, setCamposExtrasValores] = useState<
    CampoExtraValor[]
  >([]);
  const { user } = useAuth();
  const { turma, uploads, dispensas } = useTurma();
  const toast = useToast();

  const schema = yup.object().shape({
    endereco: yup.string().required(),
    cidade: yup.string().required(),
    bairro: yup.string().required(),
    uf: yup.string().required(),
    cep: yup
      .string()
      .max(10, 'O cep não está correto')
      .min(8, 'O cep não está correto')
      .required(),
    telefone: yup
      .string()
      .max(60, 'O telefone não pode possuir mais de 60 caracteres!')
      .required('Telefone é requerido'),
    local_prova:
      turma && turma.locais_provas.length > 0
        ? yup.string().required('Um local deve ser escolhido')
        : yup.string(),
  });

  const {
    isOpen: isOpenConfirmaInscricao,
    onOpen: onOpenConfirmaInscricao,
    onClose: onCloseConfirmaInscricao,
  } = useDisclosure();
  const { register, handleSubmit, errors, setValue } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      telefone: '',
      local_prova: '',
    },
  });
  const onSubmit = (data: Inputs): void => {
    let inscricaoDados: any = {
      id_turma: turma?.id_turma,
      pes_codigo: user.matricula,
      endereco: data.endereco.toUpperCase(),
      cidade: data.cidade.toUpperCase(),
      bairro: data.bairro.toUpperCase(),
      uf: data.uf.toUpperCase(),
      cep: data.cep,
      telefone: data.telefone,
      local_prova: data.local_prova || '',
    };

    if (turma && turma?.campos_extras.length > 0) {
      if (
        camposExtrasValores.length !== turma.campos_extras.length ||
        camposExtrasValores.some((campo) => !campo.valor)
      ) {
        toast({
          title: 'Validação!',
          description:
            'Todos os campos dos dados complementares devem ser preenchidos',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });

        return;
      }
      inscricaoDados = {
        ...inscricaoDados,
        campos_extras: camposExtrasValores,
      };
    }

    setDados(inscricaoDados);

    onOpenConfirmaInscricao();
  };

  const prontoEnvio = useMemo(() => {
    const documentosRequerido = turma?.documentos ? turma.documentos.length : 0;
    const uploadsCompletos = uploads ? uploads.length : 0;

    const dispensasTotal = dispensas ? dispensas.length : 0;

    return (
      documentosRequerido - dispensasTotal === uploadsCompletos &&
      Object.keys(errors).length === 0
    );
  }, [uploads, turma, errors, dispensas]);

  const locais = useMemo(() => {
    const locaisTurma = turma ? turma.locais_provas : [];
    return locaisTurma.map((local) => {
      return { label: local.nome, value: local.nome };
    });
  }, [turma]);

  useEffect(() => {
    async function loadEndereco(): Promise<void> {
      try {
        const response = await api.get(`policiais/${user.matricula}/endereco`);

        const endereco = response.data.endereco as Endereco;
        setValue('endereco', endereco.pes_endereco);
        setValue('cidade', endereco.pes_cidade);
        setValue('bairro', endereco.pes_bairro);
        setValue('cep', endereco.pes_cep);
        setValue('uf', endereco.pes_estado);
      } catch (error) {
        console.log(error);
      }
    }

    loadEndereco();
  }, [user, setValue]);

  return (
    <>
      <TituloPagina
        title={
          turma?.tipo_turma.tipo === 'consulta'
            ? 'Formulário'
            : 'Dados do Processo Seletivo'
        }
      />

      <BoxContent>
        {turma && (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Accordion
                defaultIndex={[0]}
                label={
                  turma.tipo_turma.tipo === 'consulta'
                    ? 'Dados da consulta'
                    : 'Dados do Processo Seletivo'
                }
                mt="2"
              >
                <Flex direction="column" fontSize="0.90rem">
                  <Flex direction="row">
                    <Text fontWeight="600" mr="8px">
                      Nome:
                    </Text>
                    <Text>
                      {turma.tipo_turma.nome}-{turma.titulo}
                    </Text>
                  </Flex>

                  <Flex direction="row">
                    <Text fontWeight="600" mr="8px">
                      Data Início das Inscrições:
                    </Text>
                    <Text>
                      {new Date(turma.data_inicio_inscricao).toLocaleDateString(
                        'pt-BR',
                      )}
                    </Text>
                  </Flex>
                  <Flex direction="row">
                    <Text fontWeight="600" mr="8px">
                      Data Fim das Inscrições:
                    </Text>
                    <Text>
                      {new Date(turma.data_fim_inscricao).toLocaleDateString(
                        'pt-BR',
                      )}
                    </Text>
                  </Flex>
                  {turma.instrucoes && (
                    <Alert status="warning" borderRadius="8px" mt="16px">
                      <AlertIcon />
                      <Box flex="1">
                        <AlertTitle>Informações Adicionais</AlertTitle>
                        <AlertDescription display="block">
                          {parse(turma.instrucoes)}
                        </AlertDescription>
                      </Box>
                    </Alert>
                  )}
                </Flex>
              </Accordion>
              {turma.publicacoes.length > 0 && (
                <Accordion defaultIndex={[0]} label="Publicações" mt="2">
                  <UnorderedList>
                    {turma.publicacoes.map((publicacao) => (
                      <ListItem key={publicacao.id_publicacao}>
                        <Link
                          color="#3182ce"
                          href={`${process.env.REACT_APP_URL_API}/files/${publicacao.filename}.${publicacao.ext}`}
                          isExternal
                        >
                          {publicacao.titulo}
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Accordion>
              )}

              <Accordion defaultIndex={[0]} label="Dados do PM" mt="2">
                <Box display="flex" flexDirection="column" fontSize="0.90rem">
                  <Box>
                    <Label>Nome:</Label>
                    <span>{user.nome}</span>
                  </Box>

                  <Box>
                    <Label>Posto/Grad.:</Label>
                    <span>{user.graduacao?.gra_nome}</span>
                  </Box>
                  <Flex
                    direction={{
                      sm: 'column',
                      md: 'row',
                      lg: 'row',
                      xl: 'row',
                    }}
                  >
                    {user.graduacao && user.graduacao.gra_codigo >= 12 && (
                      <Box marginRight="16px">
                        <Label>Número:</Label>
                        <span>{user.pm_numero}</span>
                      </Box>
                    )}
                    <Box>
                      <Label>Matrícula:</Label>
                      <span>{user.matricula}</span>
                    </Box>
                  </Flex>
                </Box>
                <Box marginLeft={{ base: '8px', md: '0px' }} fontSize="0.90rem">
                  <Label>Opm:</Label>
                  <Text>{`${user.opm?.uni_nome} - ${user.opm?.uni_sigla}`}</Text>
                </Box>
                <Stack
                  mt="16px"
                  fontSize="0.90rem"
                  direction={{ sm: 'column', md: 'row' }}
                >
                  <FormControl
                    id="Endereço"
                    isRequired
                    isInvalid={!!errors.endereco}
                  >
                    <FormLabel fontSize="0.90rem">Endereço</FormLabel>
                    <Input fontSize="0.90rem" name="endereco" ref={register} />
                    <FormErrorMessage>
                      {errors.endereco?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="bairro"
                    isRequired
                    isInvalid={!!errors.bairro}
                  >
                    <FormLabel fontSize="0.90rem">Bairro</FormLabel>
                    <Input fontSize="0.90rem" name="bairro" ref={register} />
                    <FormErrorMessage>
                      {errors.bairro?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
                <Stack
                  mt="8px"
                  fontSize="0.90rem"
                  direction={{ sm: 'column', md: 'row' }}
                >
                  <FormControl
                    id="cidade"
                    isRequired
                    isInvalid={!!errors.cidade}
                  >
                    <FormLabel fontSize="0.90rem">Cidade</FormLabel>
                    <Input fontSize="0.90rem" name="cidade" ref={register} />
                    <FormErrorMessage>
                      {errors.cidade?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl id="uf" isRequired isInvalid={!!errors.uf}>
                    <FormLabel fontSize="0.90rem">Estado</FormLabel>
                    <Input fontSize="0.90rem" name="uf" ref={register} />
                    <FormErrorMessage>{errors.uf?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl id="cep" isRequired isInvalid={!!errors.cep}>
                    <FormLabel fontSize="0.90rem">CEP</FormLabel>
                    <Input
                      as={InputMask}
                      mask="99999-999"
                      fontSize="0.90rem"
                      name="cep"
                      ref={register}
                    />
                    <FormErrorMessage>{errors.cep?.message}</FormErrorMessage>
                  </FormControl>
                </Stack>
                <Stack mt="8px" direction={{ sm: 'column', md: 'row' }}>
                  <FormControl
                    id="telefone"
                    isRequired
                    isInvalid={!!errors.telefone}
                  >
                    <FormLabel fontSize="0.90rem">Telefone</FormLabel>
                    <Input
                      as={InputMask}
                      mask="(99) 99999-9999"
                      type="tel"
                      fontSize="0.90rem"
                      name="telefone"
                      ref={register}
                    />
                    <FormErrorMessage>
                      {errors.telefone?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
              </Accordion>
              {turma.campos_extras.length > 0 && (
                <DadosComplementares
                  setCamposExtrasValores={setCamposExtrasValores}
                />
              )}
              {turma.documentos.length > 0 && (
                <Accordion
                  defaultIndex={[0]}
                  label="Documentos Exigidos"
                  mt="2"
                >
                  <TableAnexos documentos={turma.documentos} />
                </Accordion>
              )}
              {turma.locais_provas.length > 0 && (
                <Accordion defaultIndex={[0]} label="Polo de Prova" mt="2">
                  <FormControl
                    id="Local_prova"
                    isRequired
                    isInvalid={!!errors.telefone}
                  >
                    <FormLabel fontSize="0.90rem">Polo:</FormLabel>
                    <Select
                      fontSize="0.90rem"
                      name="local_prova"
                      ref={register}
                      placeholder="Selecione uma opção"
                    >
                      {locais.map((loc) => (
                        <option key={loc.label} value={loc.value}>
                          {loc.label}
                        </option>
                      ))}
                    </Select>

                    <FormErrorMessage>
                      {errors.local_prova?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Accordion>
              )}

              <Flex mt="16px" direction="row" justifyContent="center">
                <Button
                  size="sm"
                  loadingText="Enviando"
                  colorScheme="green"
                  type="submit"
                  isDisabled={!prontoEnvio}
                >
                  {turma.tipo_turma.tipo === 'consulta'
                    ? 'Enviar Resposta'
                    : 'Fazer inscrição'}
                </Button>
              </Flex>
            </form>
            <ModalConfirmaInscricao
              isOpen={isOpenConfirmaInscricao}
              onClose={onCloseConfirmaInscricao}
              dados={dados}
              turma={turma}
            />
          </>
        )}
      </BoxContent>
    </>
  );
};

export default Inscrever;
