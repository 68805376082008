import React, { useEffect, useMemo, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Text,
} from '@chakra-ui/react';
import { useTurma } from 'contexts/turma';
import BoxContent from '../../components/BoxContent';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import Accordion from '../../components/Accordion';
import TableAnexos from './TableAnexos';
import ImageDefault from '../../assets/pm_foto.jpg';

interface ITurma {
  id_turma: number;
  titulo: number;
  turma_tipo: {
    nome: string;
  };
}

interface IOptionTurma {
  value: number;
  label: string;
}

interface ITurmaFull {
  id_turma: number;
  titulo: number;
  data_inicio_inscricao: string;
  data_fim_inscricao: string;
  carga_horaria: number;
  vagas: number;
  documentos: {
    id_documento_turma: number;
    nome: string;
    slug: string;
  }[];
  tipo_turma: {
    id_tipo_turma: number;
    nome: string;
    sigla: string;
    descricao: string;
    militar: boolean;
  };
}

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

const Turma: React.FC = () => {
  const { turma } = useTurma();

  return (
    <>
      <TituloPagina title="Informação sobre turma" />
      <BoxContent>
        {turma && (
          <>
            <Accordion defaultIndex={[0]} label="Dados do Turma" mt="2">
              <Flex direction="column" fontSize="0.90rem">
                <Flex direction="row">
                  <Text fontWeight="600" mr="8px">
                    Nome:
                  </Text>
                  <Text>
                    {turma.tipo_turma.nome}-{turma.tipo_turma.sigla}
                  </Text>
                </Flex>

                <Flex direction="row">
                  <Text fontWeight="600" mr="8px">
                    Número de Vagas:
                  </Text>
                  <Text>{turma.vagas}</Text>
                </Flex>
              </Flex>
            </Accordion>
            <Accordion defaultIndex={[0]} label="Documentos Requeridos" mt="2">
              <TableAnexos documentos={turma.documentos} />
            </Accordion>
            <Flex mt="16px" direction="row" justifyContent="center">
              <Button size="sm" colorScheme="green">
                Fazer Inscrição
              </Button>
            </Flex>
          </>
        )}
      </BoxContent>
    </>
  );
};

export default Turma;
