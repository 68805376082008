import React, { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import { format } from 'date-fns';
import { MdSearch } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useTurma } from 'contexts/turma';
import { Table } from './styles';

interface IDocumento {
  id_documento_inscricao: number;
  id_inscricao: number;
  id_documento_turma: number;
  dispensado: boolean;
  filename: string;
  ext: string;

  documento: {
    id_documento_turma: number;
    id_turma: number;
    nome: string;
    slug: string;
    tipo_upload: string;
    descricao: string;
  };
}
interface IInscricao {
  id_inscricao: number;
  id_turma: number;
  turma: {
    titulo: string;
  };
  status: 'pendente' | 'deferido' | 'indeferido';
  numero_inscricao: string;
  data_inscricao: string;
  pes_codigo: string;
  endereco: string;
  cidade: string;
  uf: string;
  cep: string;
  email: string;
  telefone: string;
  documentos: IDocumento[];
}
interface IProps {
  inscricoes: IInscricao[];
}

const TableInscricoes: React.FC<IProps> = ({ inscricoes }) => {
  const formattedDate = useCallback((data: string) => {
    return format(new Date(data), 'dd/MM/yyyy');
  }, []);

  const history = useHistory();

  const { loadInscricao } = useTurma();

  const statusInscricao = useCallback((status: string) => {
    switch (status) {
      case 'pendente':
        return 'INSCRITO';
      case 'deferido':
        return 'DEFERIDO';
      case 'indeferido':
        return 'INDEFERIDO';

      default:
        return 'error';
    }
  }, []);
  return (
    <Box
      overflowX="auto"
      overflowY="hidden"
      whiteSpace="nowrap"
      maxWidth="100%"
      css={{
        '&::-webkit-scrollbar': {
          width: '4px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#ccc',
          borderRadius: '24px',
        },
      }}
    >
      <Box maxWidth={{ sm: '280px', md: '100%', lg: '100%', xl: '100%' }}>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Data</th>
              <th>Inscrição</th>
              <th>Nome</th>
              <th>Situação</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {inscricoes.length > 0 ? (
              inscricoes.map((inscricao, index) => (
                <tr key={inscricao.id_inscricao}>
                  <td>{index + 1}</td>
                  <td>{formattedDate(inscricao.data_inscricao)}</td>
                  <td>{inscricao.numero_inscricao}</td>
                  <td>{inscricao.turma.titulo}</td>
                  <td>{statusInscricao(inscricao.status)}</td>
                  <td>
                    <MdSearch
                      size={20}
                      onClick={async () => {
                        await loadInscricao(inscricao.id_inscricao);
                        history.push('/inscricao');
                      }}
                      cursor="pointer"
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  Você ainda não possui inscrições no sistema.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default TableInscricoes;
