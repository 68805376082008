import styled from 'styled-components';

export const Table = styled.table`
  margin-top: 8px;
  font-size: 14px;
  border-collapse: collapse;
  width: 100%;
  margin-top tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr {
    border-bottom: 1px solid #ddd;
  }

  th,
  td {
    padding: 8px;
    text-align: left;
  }
  th.actions {
  }
  td.actions {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    /* button {
      background: none;
      border: none;
    }
    > div + div {
      margin-left: 8px;
    } */
  }
`;
