import React, { useMemo } from 'react';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
} from '@chakra-ui/react';
import { useTurma } from 'contexts/turma';
import Accordion from '../../components/Accordion';

type TipoCampo = 'Inteiro' | 'Opções';

type IProps = {
  setCamposExtrasValores: (dados: any) => void;
};

const DadosComplementares: React.FC<IProps> = ({ setCamposExtrasValores }) => {
  const { turma } = useTurma();

  const camposExtras = useMemo(() => {
    return turma?.campos_extras ? turma.campos_extras : [];
  }, [turma]);

  function handleOnChange(
    id_campo_extra_turma: number,
    valor: string,
    tipoCampo: TipoCampo,
  ): void {
    let valorFinal = valor;
    if (tipoCampo === 'Opções') {
      const campoExtra = camposExtras.find(
        (campo) => campo.id_campo_extra_turma === id_campo_extra_turma,
      );

      const Opcao = campoExtra?.opcoes.find(
        (opt) => opt.id_opcao_campo_extra === Number(valor),
      );

      valorFinal = Opcao ? Opcao?.opcao : '';
    }
    setCamposExtrasValores((prevState: any) => {
      const camposFiltrados = prevState.filter(
        (camp: any) => camp.id_campo_extra_turma !== id_campo_extra_turma,
      );
      return [...camposFiltrados, { id_campo_extra_turma, valor: valorFinal }];
    });
  }

  return (
    <Accordion defaultIndex={[0]} label="Dados complementares" mt="2">
      <SimpleGrid minChildWidth="160px" spacing={2}>
        {camposExtras.map((campo) => {
          if (campo.tipo_campo_extra.nome === 'Opções') {
            return (
              <FormControl
                id={campo.campo_slug}
                isRequired
                key={campo.id_campo_extra_turma}
              >
                <FormLabel fontSize="0.90rem">{campo.campo_label}</FormLabel>
                <Select
                  fontSize="0.90rem"
                  name={campo.campo_slug}
                  placeholder="Selecione uma opção"
                  onChange={(e) =>
                    handleOnChange(
                      campo.id_campo_extra_turma,
                      e.target.value,
                      campo.tipo_campo_extra.nome,
                    )
                  }
                >
                  {campo.opcoes.map((opcao) => (
                    <option
                      value={opcao.id_opcao_campo_extra}
                      key={opcao.id_opcao_campo_extra}
                    >
                      {opcao.opcao}
                    </option>
                  ))}
                </Select>

                <FormErrorMessage>teste</FormErrorMessage>
              </FormControl>
            );
          }
          if (campo.tipo_campo_extra.nome === 'Inteiro') {
            return (
              <FormControl
                id={campo.campo_slug}
                isRequired
                key={campo.id_campo_extra_turma}
              >
                <FormLabel fontSize="0.90rem">{campo.campo_label}</FormLabel>
                <Input
                  fontSize="0.90rem"
                  name={campo.campo_slug}
                  type="number"
                  placeholder="0"
                  onChange={(e) =>
                    handleOnChange(
                      campo.id_campo_extra_turma,
                      e.target.value,
                      campo.tipo_campo_extra.nome,
                    )
                  }
                />

                <FormErrorMessage>teste</FormErrorMessage>
              </FormControl>
            );
          }
          return <></>;
        })}
      </SimpleGrid>
    </Accordion>
  );
};

export default DadosComplementares;
