import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  FormErrorMessage,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useTurma } from 'contexts/turma';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  id_inscricao: number;
}

const schema = Yup.object().shape({
  motivo_indeferido: Yup.string()
    .max(250, 'Tamanho máximo de 250 caracteres!')
    .required('Um motivo deve ser adicionado!'),
});

interface IFormInputs {
  motivo_indeferido: string;
}

const ModalDeferirIndeferir: React.FC<IProps> = ({
  isOpen,
  onClose,
  id_inscricao,
}) => {
  const { validaInscricao } = useTurma();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any): Promise<void> => {
    setIsLoading(true);
    try {
      await validaInscricao({
        id_inscricao,
        status: 'indeferido',
        ...data,
      });
      onClose();
    } catch (error) {
      onClose();
    }
    setIsLoading(false);
  };

  const { handleSubmit, errors, register } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      motivo_indeferido: undefined,
    },
  });

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Indeferir Inscrição</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          Você tem certeza que deseja INDEFERIR a inscrição do candidato?
          <form>
            <FormControl
              mt="8px"
              id="motivo_indeferido"
              isRequired
              isInvalid={!!errors.motivo_indeferido}
            >
              <FormLabel fontSize="0.90rem">Motivo do Indeferimento</FormLabel>

              <Textarea
                name="motivo_indeferido"
                ref={register}
                placeholder="Digite o motivo"
                size="sm"
              />
              <FormErrorMessage>
                {errors.motivo_indeferido?.message}
              </FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="primary"
            mr={3}
            onClick={handleSubmit((data) => onSubmit(data))}
            isLoading={isLoading}
          >
            Sim
          </Button>
          <Button colorScheme="red" onClick={onClose}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalDeferirIndeferir;
