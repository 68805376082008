import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Row from '../../../components/form/Row';
import FormGroup from '../../../components/form/FormGroup';
import FormInput from '../../../components/form/FormInput';

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Um email válido é requerido!')
    .email('Um email válido é requerido!'),
});

interface IFormInputs {
  email: string;
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalAdicionarEmail: React.FC<IProps> = ({ isOpen, onClose }) => {
  const onSubmit = async (data: any): Promise<void> => {
    try {
      onClose();
    } catch (error) {
      onClose();
    }
  };

  const { handleSubmit, errors, control } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar Email</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form>
            <Row>
              <FormGroup required name="Email" cols={[12, 12, 12]}>
                <Controller
                  name="email"
                  defaultValue=""
                  control={control}
                  render={(props) => (
                    <FormInput
                      {...props}
                      autoFocus
                      error={errors.email?.message}
                    />
                  )}
                />
              </FormGroup>
            </Row>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="primary"
            mr={3}
            onClick={handleSubmit((data) => onSubmit(data))}
          >
            Salvar
          </Button>
          <Button colorScheme="red" onClick={onClose}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAdicionarEmail;
