import React, { useState } from 'react';

import { Box, useDisclosure } from '@chakra-ui/react';
import { useAuth } from 'contexts/auth';

import ModalInserirCodigo from '../ModalAdicionarAnexo';

import { Table } from './styles';

interface IDocumento {
  id_documento_turma: number;
  nome: string;
  descricao: string;
  slug: string;
}

interface IEmailFormated {
  pes_email: string;
  pes_codigo_email: number;
  status: 'pendente' | 'validado' | 'invalidado';
  id_validacao?: number;
}

interface IProps {
  documentos: IDocumento[];
}

const status = {
  validado: {
    color: '#14A64F',
    label: 'Validado',
  },
  pendente: {
    color: '#FFA500',
    label: 'Pendente',
  },
  invalidado: {
    color: '#E54242',
    label: 'Não validado',
  },
};

const AnexosTable: React.FC<IProps> = ({ documentos }) => {
  const { user } = useAuth();

  const {
    isOpen: isOpenInserirCodigo,
    onOpen: onOpenInserirCodigo,
    onClose: onCloseInserirCodigo,
  } = useDisclosure();

  const [selectedEmail, setSelectedEmail] = useState({} as IEmailFormated);

  return (
    <Box
      overflowX="auto"
      overflowY="hidden"
      whiteSpace="nowrap"
      maxWidth="100%"
      css={{
        '&::-webkit-scrollbar': {
          width: '4px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#ccc',
          borderRadius: '24px',
        },
      }}
    >
      <Box maxWidth={{ sm: '280px', md: '100%', lg: '100%', xl: '100%' }}>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Documento</th>
            </tr>
          </thead>
          <tbody>
            {documentos &&
              documentos.map((documento, index) => (
                <tr key={documento.id_documento_turma}>
                  <td>{index + 1}</td>
                  <td>{documento.nome}</td>
                </tr>
              ))}
          </tbody>
        </Table>

        {selectedEmail.id_validacao && (
          <ModalInserirCodigo
            isOpen={isOpenInserirCodigo}
            onClose={onCloseInserirCodigo}
          />
        )}
      </Box>
    </Box>
  );
};

export default AnexosTable;
