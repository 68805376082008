import React from 'react';

import { useTurma } from 'contexts/turma';
import BoxContent from '../../components/BoxContent';

import TituloPagina from '../../components/TituloPagina';
import TableInscricoes from './TableInscricoes';

const MinhasInscricoes: React.FC = () => {
  const { inscricoesUsuario } = useTurma();

  return (
    <>
      <TituloPagina title="Minhas Inscrições" />
      <BoxContent>
        {inscricoesUsuario && (
          <TableInscricoes inscricoes={inscricoesUsuario} />
        )}
      </BoxContent>
    </>
  );
};

export default MinhasInscricoes;
