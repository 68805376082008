import React, { useMemo } from 'react';
import { MdEdit, MdDashboard, MdCheck, MdList } from 'react-icons/md';
import {
  Accordion,
  Center,
  Flex,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import Logo from '../components/Logo';
import MenuItem from './Menu';
import LogoCetic from '../../../../assets/logo-cetic-35px.png';
import { Container, HeaderMenu, Footer } from './styles';
import { useAuth } from '../../../../contexts/auth';

interface ISideBarProps {
  activated: boolean;
  handleActiveSideBar(): void;
}

const ajudaItens = [
  {
    label: 'Manual',
    toExternal: `${process.env.REACT_APP_URL_API}/files/47fd23b1-b6c5-4dd9-a40c-9683a472aeb5-manual-sga.pdf`,
  },
];

const SideBar: React.FC<ISideBarProps> = ({
  activated,
  handleActiveSideBar,
}) => {
  const { user } = useAuth();
  const bg = useColorModeValue('green.500', '#5b5b58');
  const color = useColorModeValue('gray.500', 'white');
  const perfilComissao = useMemo(() => {
    return user.currentPerfil === 'SGI - COMISSAO';
  }, [user]);

  const perfilAdmin = useMemo(() => {
    return user.currentPerfil === 'SGI - ADM';
  }, [user]);

  const perfilComum = useMemo(() => {
    return user.currentPerfil === 'USUÁRIO PADRÃO';
  }, [user]);
  return (
    <Container activated={activated}>
      <HeaderMenu activated={activated}>
        <Logo activated={activated} />
      </HeaderMenu>

      <Flex
        bg={bg}
        color={color}
        direction="column"
        alignItems="initial"
        flex="1"
      >
        <Accordion allowToggle>
          <MenuItem
            to="/home"
            label="INICIAL"
            icon={MdDashboard}
            activated={activated}
          />

          <MenuItem
            to="/inscricoes"
            label="MINHAS INSCRIÇÕES"
            icon={MdEdit}
            activated={activated}
          />

          {(perfilComissao || perfilAdmin) && (
            <>
              <MenuItem
                to="/validacoes"
                label="VALIDAR"
                icon={MdCheck}
                activated={activated}
              />
              <MenuItem
                to="/lista"
                label="LISTA"
                icon={MdList}
                activated={activated}
              />
            </>
          )}

          {/* <MenuItem
            toExternal={`${process.env.REACT_APP_URL_API}/files/adba51c1-2cd5-4289-9995-12c5452362ea.pdf`}
            label="MANUAL"
            icon={MdHelp}
            activated={activated}
          /> */}
        </Accordion>
      </Flex>
      <Footer activated={activated}>
        <Center w="100%">
          {activated && <Image src={LogoCetic} alt="logo cetic" />}
        </Center>
      </Footer>
    </Container>
  );
};

export default SideBar;
