import { Box, FormControl, FormLabel, HStack, Select } from '@chakra-ui/react';
import { useTurma } from 'contexts/turma';
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import BoxContent from '../../components/BoxContent';
import DataTable, { IColumns } from '../../components/DataTable';
import TituloPagina from '../../components/TituloPagina';
import api from '../../services/api';

interface IFields {
  [key: string]: string | number;
}

const Validacoes: React.FC = () => {
  const {
    loadInscricaoValidacao,
    turmasPessoaComissao,
    turmaSelecionada,
    selecionaTurma,
  } = useTurma();

  const history = useHistory();
  const colunas: IColumns = [
    {
      field: 'pes_codigo',
      text: 'Matrícula',
      type: {
        name: 'text',
      },
    },
    {
      field: 'gra_sigla',
      text: 'Post./Grad.',
      type: {
        name: 'text',
      },
    },
    {
      field: 'pes_nome',
      text: 'Nome',
      type: {
        name: 'text',
      },
    },

    {
      field: 'uni_sigla',
      text: 'Opm',
      type: {
        name: 'text',
      },
    },

    {
      field: 'status',
      text: 'Situação',
      type: {
        name: 'text',
      },
    },

    {
      field: 'numero_inscricao',
      text: 'Inscrição',
      type: {
        name: 'text',
      },
    },
  ];

  async function handleClickEditar(row: IFields): Promise<void> {
    await loadInscricaoValidacao(Number(row.id_inscricao));
    history.push(`/validar`);
  }

  const options = {
    serverData: {
      url: `/inscricoes`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `turma=${turmaSelecionada}&status=pendente`,
    },
    actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <FaSearch size={13} />,
          tooltip: 'Visualizar',

          getRow: handleClickEditar,
        },
      ],
    },
    filters: [],
    search: {
      searchable: true,
      label: 'Pesquisar Matrícula:',
      fields: ['pes_codigo'],
    },
  };

  return (
    <>
      <TituloPagina title="Inscritos para validação" />
      <BoxContent>
        <HStack>
          <FormControl id="turma">
            <FormLabel>Turma:</FormLabel>
            <Select
              // placeholder="Selecione uma Turma"
              value={turmaSelecionada}
              onChange={(e) => selecionaTurma(Number(e.target.value))}
            >
              <option value={0}>Selecione uma Turma</option>
              {turmasPessoaComissao &&
                turmasPessoaComissao.map((turmaPessoa) => (
                  <option
                    key={turmaPessoa.id_turma}
                    value={turmaPessoa.id_turma}
                  >
                    {turmaPessoa.turma.tipo_turma.nome} -{' '}
                    {turmaPessoa.turma.titulo}
                  </option>
                ))}
            </Select>
          </FormControl>
        </HStack>
        {turmaSelecionada > 0 && (
          <Box maxWidth="calc(100vw - 50px)">
            <DataTable columns={colunas} options={options} />
          </Box>
        )}
      </BoxContent>
    </>
  );
};

export default Validacoes;
