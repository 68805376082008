import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import Home from '../pages/Home';
import Inscricao from '../pages/Inscricao';
import Inscrever from '../pages/Inscrever';
import MinhasInscricoes from '../pages/MinhasInscricoes';
import Turma from '../pages/Turma';
import Validacoes from '../pages/Validacoes';
import Validacao from '../pages/Validacao';

import Route from './Route';
import SignIn from '../pages/SignIn';
import Inscricoes from '../pages/Inscricoes';

const Routes: React.FC = () => {
  // const { user } = useAuth();
  // const perfilComissao = useMemo(() => {
  //   return user.currentPerfil === 'SGI - COMISSAO';
  // }, [user]);

  // const perfilAdmin = useMemo(() => {
  //   return user.currentPerfil === 'SGI - ADM';
  // }, [user]);
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/home" component={Home} isPrivate />
      <Route path="/inscricao" component={Inscricao} isPrivate />
      <Route path="/inscrever" component={Inscrever} isPrivate />
      <Route path="/inscricoes" component={MinhasInscricoes} isPrivate />
      <Route path="/lista" component={Inscricoes} isPrivate />
      <Route path="/turma" component={Turma} isPrivate />
      <Route path="/validacoes" component={Validacoes} isPrivate />
      <Route path="/validar" component={Validacao} isPrivate />
      <Route />
    </Switch>
  );
};

export default Routes;
