import { Flex, Text, Image, useToast } from '@chakra-ui/react';
import { useAuth } from 'contexts/auth';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CriterioTurma, useTurma } from '../../../contexts/turma';
import possuiCriterios from './possuiCriterios';
import LogoPadrao from '../../../assets/logo-pmce-min.png';

interface ITurmaProps {
  titulo: string;
  inicio: string;
  fim: string;
  id: number;
  filename?: string;
  aberto: boolean;
  criterios?: CriterioTurma[];
  onClick?(): void;
}

const TurmaBox: React.FC<ITurmaProps> = ({
  titulo,
  filename,
  id,
  inicio,
  fim,
  aberto,
  criterios = [],
}) => {
  const history = useHistory();
  const { user } = useAuth();
  const toast = useToast();
  const { loadInscricaoByPesCodigoTurma, loadTurma } = useTurma();
  return (
    <Flex
      width={{ sm: '140px', md: '160px' }}
      minHeight={{ sm: '80px', md: '100px' }}
      borderRadius="4px"
      border="2px solid #999"
      color="#666"
      background="#fff"
      _hover={aberto ? { border: '2px solid green', color: '#333' } : {}}
      transition="all 0.3s"
      p="4px"
      direction="column"
      justify="center"
      align="center"
      cursor={aberto ? 'pointer' : ''}
      onClick={async () => {
        if (aberto) {
          if (possuiCriterios({ criterios, user })) {
            const response = await loadInscricaoByPesCodigoTurma(
              user.matricula,
              id,
            );
            if (response) {
              history.push('inscricao');
            } else {
              await loadTurma(id);
              history.push('inscrever');
            }
          } else {
            toast({
              title: 'Aviso!',
              description: 'Você não possui os critérios para esta inscrição!',
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            });
          }
        } else {
          toast({
            title: 'Aviso!',
            description: 'A inscrição ainda não iniciou!',
            status: 'warning',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
      }}
    >
      <Image
        height={{ sm: '30px', md: '45px' }}
        src={
          filename
            ? `${process.env.REACT_APP_URL_API}/assets/${filename}`
            : LogoPadrao
        }
      />
      <Text fontSize="sm" fontWeight="600" lineHeight="18px">
        {titulo}
      </Text>

      <Text fontSize={{ sm: 'xx-small', md: 'xs' }} fontWeight="400">
        {inicio} - {fim}
      </Text>
    </Flex>
  );
};

export default TurmaBox;
