import React, { useMemo } from 'react';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
} from '@chakra-ui/react';
import { useTurma } from 'contexts/turma';
import Accordion from '../../components/Accordion';

const DadosComplementares: React.FC = () => {
  const { inscricao } = useTurma();

  const camposExtras = useMemo(() => {
    return inscricao?.campos_extras ? inscricao?.campos_extras : [];
  }, [inscricao]);

  return (
    <Accordion defaultIndex={[0]} label="Dados complementares" mt="2">
      <SimpleGrid minChildWidth="160px" spacing={2}>
        {camposExtras.map((campo) => {
          if (campo.campo_extra_turma.tipo_campo_extra.nome === 'Opções') {
            return (
              <FormControl
                id={campo.campo_extra_turma.campo_slug}
                isRequired
                key={campo.campo_extra_turma.campo_slug}
              >
                <FormLabel fontSize="0.90rem">
                  {campo.campo_extra_turma.campo_label}
                </FormLabel>
                <Select
                  fontSize="0.90rem"
                  name={campo.campo_extra_turma.campo_slug}
                  placeholder="Selecione uma opção"
                  value={campo.valor}
                  isDisabled
                >
                  <option value={campo.valor}>{campo.valor}</option>
                </Select>

                <FormErrorMessage>teste</FormErrorMessage>
              </FormControl>
            );
          }
          if (campo.campo_extra_turma.tipo_campo_extra.nome === 'Inteiro') {
            return (
              <FormControl
                id={campo.campo_extra_turma.campo_slug}
                isRequired
                key={campo.id_campo_extra_turma}
              >
                <FormLabel fontSize="0.90rem">
                  {campo.campo_extra_turma.campo_label}
                </FormLabel>
                <Input
                  fontSize="0.90rem"
                  name={campo.campo_extra_turma.campo_slug}
                  type="number"
                  placeholder="0"
                  isDisabled
                  value={campo.valor}
                />

                <FormErrorMessage>teste</FormErrorMessage>
              </FormControl>
            );
          }
          return <></>;
        })}
      </SimpleGrid>
    </Accordion>
  );
};

export default DadosComplementares;
