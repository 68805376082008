import React, { createContext, useContext, useState, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';

import api from '../services/api';

export type TipoCriterio = {
  id_tipo_criterio: number;
  nome: string;
  descricao: string;
};

export type CriterioTurma = {
  id_criterio_turma: number;
  id_turma: number;
  id_tipo_criterio: number;
  operador_comparacao: string;
  valor_comparacao: string;
  tipo_criterio: TipoCriterio;
};

interface ITurma {
  id_turma: number;
  id_tipo_turma: number;
  titulo: string;
  publicacao: string;
  data_inicio_inscricao: string;
  data_fim_inscricao: string;
  carga_horaria: number;
  vagas: number;
  instrucoes?: string;
  criterios?: CriterioTurma[];
  publicacoes: {
    id_publicacao: number;
    id_turma: number;
    titulo: string;
    filename: string;
    ext: string;
  }[];
  locais_provas: {
    id_local_prova: number;
    id_turma: number;
    nome: string;
  }[];
  documentos: {
    id_documento_turma: number;
    id_turma: number;
    nome: string;
    slug: string;
    descricao: string;
    dispensavel: boolean;
    dispensavel_motivo: string | null;
    tipo_upload: string;
  }[];
  tipo_turma: {
    id_tipo_turma: number;
    tipo: 'curso' | 'concurso' | 'treinamento' | 'taf' | 'consulta';
    nome: string;
    sigla: string;
    descricao: string;
    militar: boolean;
    ativo: boolean;
    logo_path: string;
    logo_filename: string;
    logo_ext: string;
  };
  campos_extras: {
    campo_label: string;
    campo_slug: string;
    id_tipo_campo_extras: number;
    id_campo_extra_turma: number;
    tipo_campo_extra: {
      nome: 'Inteiro' | 'Opções';
    };
    opcoes: {
      id_opcao_campo_extra: number;
      opcao: string;
    }[];
  }[];
}
interface IDocumento {
  id_documento_inscricao: number;
  id_inscricao: number;
  id_documento_turma: number;
  dispensado: boolean;
  valido: boolean | null;
  motivo_invalido: string;
  filename: string;
  ext: string;

  documento: {
    id_documento_turma: number;
    id_turma: number;
    nome: string;
    slug: string;
    tipo_upload: string;
    descricao: string;
  };
}

interface IPessoa {
  pm_numero: string;
  pessoa: {
    pes_nome: string;
  };
  graduacao: {
    gra_codigo: number;
    gra_sigla: string;
    gra_nome: string;
  };
  opm: {
    uni_codigo: number;
    uni_nome: string;
    uni_sigla: string;
  };
}

interface IInscricao {
  id_inscricao: number;
  id_turma: number;
  turma: ITurma;
  pessoa: IPessoa;
  status: 'pendente' | 'deferido' | 'indeferido';
  numero_inscricao: string;
  motivo_indeferido: string;
  data_inscricao: string;
  local_prova: string;
  talento: string;
  talento_detalhe: string;
  pes_codigo: string;
  endereco: string;
  cidade: string;
  bairro: string;
  uf: string;
  cep: string;
  email: string;
  telefone: string;
  documentos: IDocumento[];
  campos_extras: {
    id_campo_extra_turma: number;
    valor: string;
    campo_extra_turma: {
      campo_label: number;
      campo_slug: string;
      tipo_campo_extra: {
        nome: 'Inteiro' | 'Opções';
      };
    };
  }[];
}

interface ITurmaPartial {
  id_turma: number;
  funcao: string;
  turma: {
    titulo: string;
    tipo_turma: {
      nome: string;
    };
  };
}

type IListTurmas = {
  encerradas: {
    titulo: string;
    id_turma: number;
    data_inicio_inscricao: string;
    data_fim_inscricao: string;
    tipo_turma: {
      logo_filename: string;
      logo_ext: string;
    };
  }[];

  previstas: {
    titulo: string;
    id_turma: number;
    data_inicio_inscricao: string;
    data_fim_inscricao: string;
    tipo_turma: {
      logo_filename: string;
      logo_ext: string;
    };
  }[];

  abertas: {
    titulo: string;
    id_turma: number;
    data_inicio_inscricao: string;
    data_fim_inscricao: string;
    tipo_turma: {
      logo_filename: string;
      logo_ext: string;
    };
    criterios?: CriterioTurma[];
  }[];
};

type IUpload = {
  index: number;
  file: File;
  id_documento_turma: number;
  filename: string;
  hash_md5: string;
  ext: string;
};

interface ITurmaContextData {
  turma: ITurma | undefined;
  inscricao: IInscricao | undefined;
  idInscricaoAtual: number | undefined;
  setIdInscricaoAtual(id: number): void;
  inscricoesUsuario: IInscricao[] | undefined;
  turmas: IListTurmas;
  turmasPessoaComissao: ITurmaPartial[];
  uploads: { index: number; file: File }[];
  dispensas: { index: number; slug: string }[];
  idTurmaSelecionada: number | undefined;
  turmaSelecionada: number;
  selecionaTurma(index: number): void;
  setIdTurmaSelecionada(id: number): void;
  loadTurma(id: number): Promise<void>;
  loadInscricao(id: number): Promise<void>;
  loadInscricaoByPesCodigoTurma(
    pes_codigo: string,
    id_turma: number,
  ): Promise<any>;
  validaDocumento(dados: {
    id_documento_inscricao: number;
    valido: boolean;
    motivo_invalido: string;
  }): Promise<void>;

  validaDocumentoDispensado(dados: {
    id_documento_turma: number;
    id_inscricao: number;
    motivo_invalido: string;
    valido: boolean;
  }): Promise<void>;
  validaInscricao(dados: {
    id_inscricao: number;
    status: string;
  }): Promise<void>;
  revalidarInscricao(id_inscricao: number): Promise<void>;
  loadInscricaoValidacao(id: number): Promise<void>;
  loadInscricoesUsuario(matricula: string): Promise<void>;
  loadTurmas(): Promise<void>;
  loadTurmasPessoaComissao(pes_codigo: string): Promise<void>;
  createInscricao(dados: any): Promise<any>;
  adicionaUpload(uploadDados: IUpload): void;
  adicionaDispensa(index: number, slug: string): void;
  removeDispensa(index: number): void;
  removeUpload(index: number): void;
}

const TurmaContext = createContext<ITurmaContextData>({} as ITurmaContextData);

export const TurmaProvider: React.FC = ({ children }) => {
  const toast = useToast();

  const [turma, setTurma] = useState<ITurma | undefined>(undefined);
  const [turmasPessoaComissao, setTurmasPessoaComissao] = useState<
    ITurmaPartial[]
  >(() => {
    const turmas = sessionStorage.getItem('@pmce-cetic-sgi:turmas-comissao');

    if (turmas) {
      return JSON.parse(turmas);
    }

    return [] as ITurmaPartial[];
  });
  const [idInscricaoAtual, setIdInscricaoAtual] = useState<number | undefined>(
    undefined,
  );
  const [inscricao, setInscricao] = useState<IInscricao | undefined>(undefined);
  const [uploads, setUploads] = useState<IUpload[]>([]);
  const [dispensas, setDispensas] = useState<{ index: number; slug: string }[]>(
    [],
  );
  const [inscricoesUsuario, setInscricoesUsuario] = useState<IInscricao[]>(
    () => {
      const inscricoes = sessionStorage.getItem(
        '@pmce-cetic-sgi:inscricoes-usuario',
      );

      if (inscricoes) {
        return JSON.parse(inscricoes);
      }

      return [] as IInscricao[];
    },
  );
  const [turmas, setTurmas] = useState<IListTurmas>({} as IListTurmas);
  const [idTurmaSelecionada, setIdTurmaSelecionada] = useState<
    number | undefined
  >(undefined);

  const [turmaSelecionada, setTurmaSelecionada] = useState<number>(() => {
    const idTurma = sessionStorage.getItem('@pmce-cetic-sgi:turma-selecionada');

    if (idTurma) {
      return JSON.parse(idTurma);
    }

    return 0;
  });

  const loadTurma = useCallback(
    async (id: number): Promise<void> => {
      try {
        const { data } = await api.get(`turmas/${id}`);
        setTurma(data);
      } catch (error) {
        const errors = error as any;

        toast({
          title: 'Ocorreu um erro.',
          description: errors.response
            ? errors.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  const loadTurmasPessoaComissao = useCallback(
    async (pes_codigo: string): Promise<void> => {
      try {
        const { data } = await api.get(
          `turmas/comissoes?pes_codigo=${pes_codigo}`,
        );
        sessionStorage.setItem(
          `@pmce-cetic-sgi:turmas-comissao`,
          JSON.stringify(data),
        );
        setTurmasPessoaComissao(data);
      } catch (error) {
        const errors = error as any;

        toast({
          title: 'Ocorreu um erro.',
          description: errors.response
            ? errors.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  const loadInscricao = useCallback(
    async (id_inscricao: number): Promise<void> => {
      try {
        const { data } = await api.get(`inscricoes/${id_inscricao}`);
        setInscricao(data);
      } catch (error) {
        const errors = error as any;

        toast({
          title: 'Ocorreu um erro.',
          description: errors.response
            ? errors.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  const selecionaTurma = useCallback(async (id: number): Promise<void> => {
    sessionStorage.setItem(
      `@pmce-cetic-sgi:turma-selecionada`,
      JSON.stringify(id),
    );
    setTurmaSelecionada(id);
  }, []);

  const loadInscricaoByPesCodigoTurma = useCallback(
    async (pes_codigo: string, id_turma: number): Promise<any> => {
      try {
        const { data } = await api.get(
          `inscricoes/query?pes_codigo=${pes_codigo}&id_turma=${id_turma}`,
        );
        setInscricao(data);
        return data;
      } catch (error) {
        return undefined;
      }
    },
    [],
  );

  const validaDocumento = useCallback(
    async (dados: {
      id_documento_inscricao: number;
      valido: boolean;
      motivo_invalido: string;
    }): Promise<void> => {
      try {
        const { data } = await api.put(`inscricoes/documentos`, dados);

        const response = await api.get(`inscricoes/${data.id_inscricao}`);
        setInscricao(response.data);
      } catch (error) {
        const errors = error as any;
        toast({
          title: 'Ocorreu um erro.',
          description: errors.response
            ? errors.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  const validaDocumentoDispensado = useCallback(
    async (dados: {
      id_documento_turma: number;
      id_inscricao: number;
      motivo_invalido: string;
      valido: boolean;
    }): Promise<void> => {
      try {
        const dadosFormatados = {
          ...dados,
          filename: null,
          originalname: null,
          ext: null,
          dispensado: true,
        };
        const { data } = await api.post(
          `inscricoes/documentos`,
          dadosFormatados,
        );

        const response = await api.get(`inscricoes/${data.id_inscricao}`);
        setInscricao(response.data);
      } catch (error) {
        const errors = error as any;
        toast({
          title: 'Ocorreu um erro.',
          description: errors.response
            ? errors.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  const validaInscricao = useCallback(
    async (dados: {
      id_inscricao: number;
      status: 'deferido' | 'indeferido';
    }): Promise<void> => {
      try {
        await api.put(`inscricoes/`, dados);
        toast({
          title: 'Sucesso!',
          description: 'Inscrição validada com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });

        const response = await api.get(`inscricoes/${dados.id_inscricao}`);
        setInscricao(response.data);
      } catch (error) {
        const errors = error as any;
        toast({
          title: 'Ocorreu um erro.',
          description: errors.response
            ? errors.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  const revalidarInscricao = useCallback(
    async (id_inscricao: number): Promise<void> => {
      try {
        await api.put(`inscricoes/revalidar/${id_inscricao}`);
        toast({
          title: 'Sucesso!',
          description: 'Inscrição revalidada com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        const errors = error as any;
        toast({
          title: 'Ocorreu um erro.',
          description: errors.response
            ? errors.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  const loadInscricaoValidacao = useCallback(
    async (id: number): Promise<void> => {
      try {
        const { data } = await api.get(`inscricoes/${id}`);
        setInscricao(data);
        loadTurma(data.id_turma);
      } catch (error) {
        const errors = error as any;
        toast({
          title: 'Ocorreu um erro.',
          description: errors.response
            ? errors.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, loadTurma],
  );

  const loadTurmas = useCallback(async (): Promise<void> => {
    try {
      const { data } = await api.get(`turmas`);

      setTurmas(data);
      setUploads([]);
      setInscricao(undefined);
    } catch (error) {
      const errors = error as any;
      toast({
        title: 'Ocorreu um erro.',
        description: errors.response
          ? errors.response.data.message
          : 'Ocorreu um erro',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }, [toast]);

  const loadInscricoesUsuario = useCallback(
    async (matricula: string): Promise<void> => {
      try {
        const { data } = await api.get(`inscricoes?matricula=${matricula}`);
        sessionStorage.setItem(
          `@pmce-cetic-sgi:inscricoes-usuario`,
          JSON.stringify(data),
        );
        setInscricoesUsuario(data);
      } catch (error) {
        const errors = error as any;
        toast({
          title: 'Ocorreu um erro.',
          description: errors.response
            ? errors.response.data.message
            : 'Ocorreu um erro',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  const adicionaUpload = useCallback(
    (uploadDados: IUpload): void => {
      try {
        const filterUploads = uploads.filter(
          (up) => up.index !== uploadDados.index,
        );
        setUploads([...filterUploads, uploadDados]);
      } catch (error) {
        const errors = error as any;
        toast({
          title: 'Ocorreu um erro.',
          description: errors.response
            ? errors.response.data.message
            : 'Ocorreu um erro ao adicionar o arquivo!',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast, uploads],
  );

  const adicionaDispensa = useCallback(
    (index: number, slug: string): void => {
      const filterDispensas = dispensas.filter((up) => up.index !== index);
      setDispensas([...filterDispensas, { index, slug }]);
    },
    [dispensas],
  );

  const removeDispensa = useCallback(
    (index: number): void => {
      const filterDispensas = dispensas.filter((up) => up.index !== index);
      setDispensas([...filterDispensas]);
    },
    [dispensas],
  );

  const removeUpload = useCallback(
    (index: number): void => {
      const filterUploads = uploads.filter((up) => up.index !== index);
      setUploads([...filterUploads]);
    },
    [uploads],
  );

  const createInscricao = useCallback(
    async (dados: any): Promise<boolean> => {
      const documentos = uploads.map((doc) => {
        return {
          id_documento_turma: doc.id_documento_turma,
          dispensado: false,
          filename: doc.filename,
          ext: doc.ext,
        };
      });

      try {
        const formdata = new FormData();
        formdata.append('id_turma', dados.id_turma);
        formdata.append('pes_codigo', dados.pes_codigo);
        formdata.append('endereco', dados.endereco);
        formdata.append('cidade', dados.cidade);
        formdata.append('bairro', dados.bairro);
        formdata.append('uf', dados.uf);
        formdata.append('cep', dados.cep);
        formdata.append('telefone', dados.telefone);

        formdata.append('documentos', JSON.stringify(documentos));

        if (dados.campos_extras) {
          formdata.append('campos_extras', JSON.stringify(dados.campos_extras));
        }

        uploads.forEach((fl) => {
          formdata.append('files', fl.file);
        });

        let response;

        if (turma && turma.documentos.length > 0) {
          response = await api.post('inscricoes', formdata);
          setUploads([]);
        } else {
          response = await api.post('inscricoes/nofiles', dados);
        }

        toast({
          title: 'Sucesso!',
          description: `Inscrição realizada com sucesso. Um email foi enviado para ${response.data.email} como confirmação da inscrição`,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });

        loadInscricao(response.data.id_inscricao);
        return true;
      } catch (error) {
        const errors = error as any;
        setInscricao(undefined);

        toast({
          title: 'Ocorreu um erro.',
          description: errors.response
            ? errors.response.data.message
            : 'Ocorreu um erro ao realizar a inscrição!',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });

        return false;
      }
    },
    [toast, uploads, turma, loadInscricao],
  );

  return (
    <TurmaContext.Provider
      value={{
        turma,
        inscricao,
        turmas,
        uploads,
        inscricoesUsuario,
        dispensas,
        adicionaDispensa,
        removeDispensa,
        removeUpload,
        idTurmaSelecionada,
        adicionaUpload,
        createInscricao,
        setIdTurmaSelecionada,
        turmaSelecionada,
        selecionaTurma,
        loadTurma,
        loadInscricao,
        loadInscricaoByPesCodigoTurma,
        validaDocumento,
        validaDocumentoDispensado,
        validaInscricao,
        loadInscricaoValidacao,
        idInscricaoAtual,
        setIdInscricaoAtual,
        loadInscricoesUsuario,
        loadTurmas,
        loadTurmasPessoaComissao,
        turmasPessoaComissao,
        revalidarInscricao,
      }}
    >
      {children}
    </TurmaContext.Provider>
  );
};

export function useTurma(): ITurmaContextData {
  const context = useContext(TurmaContext);

  if (!context) {
    throw new Error('useTurma precisa estar dentro de TurmaContext provider');
  }
  return context;
}
