import { IUser } from 'contexts/auth';

type TipoCriterio = {
  id_tipo_criterio: number;
  nome: string;
  descricao: string;
};

type CriterioTurma = {
  id_criterio_turma: number;
  id_turma: number;
  id_tipo_criterio: number;
  operador_comparacao: string;
  valor_comparacao: string;
  tipo_criterio: TipoCriterio;
};

type Params = {
  criterios: CriterioTurma[];
  user: IUser;
};
function checaPorPostoGraduacao({ criterios, user }: Params): boolean {
  const porPostoGraduacao = criterios
    .filter((criterio) => criterio.tipo_criterio.nome === 'POSTO/GRADUACAO')
    .map((criterio) => {
      return {
        nome: criterio.tipo_criterio.nome,
        operador_comparacao: criterio.operador_comparacao,
        valor_comparacao: criterio.valor_comparacao,
      };
    });

  if (porPostoGraduacao.length > 0) {
    let possuiCriterioPorPostoGraduacao = 0;
    porPostoGraduacao.forEach((criterio) => {
      if (criterio.operador_comparacao === 'IGUAL A') {
        if (user.graduacao?.gra_codigo === Number(criterio.valor_comparacao)) {
          possuiCriterioPorPostoGraduacao = 1;
        }
      }
    });

    if (possuiCriterioPorPostoGraduacao > 0) {
      return true;
    }
    return false;
  }
  return true;
}

function checaPorMatricula({ criterios, user }: Params): boolean {
  const porMatricula = criterios
    .filter((criterio) => criterio.tipo_criterio.nome === 'MATRICULA')
    .map((criterio) => {
      return {
        nome: criterio.tipo_criterio.nome,
        operador_comparacao: criterio.operador_comparacao,
        valor_comparacao: criterio.valor_comparacao,
      };
    });

  if (porMatricula.length > 0) {
    let possuiCriterioPorMatricula = 0;
    porMatricula.forEach((criterio) => {
      if (criterio.operador_comparacao === 'IGUAL A') {
        if (user.matricula === criterio.valor_comparacao) {
          possuiCriterioPorMatricula = 1;
        }
      }
    });

    if (possuiCriterioPorMatricula > 0) {
      return true;
    }
    return false;
  }
  return true;
}
export default function possuiCriterios(dados: Params): boolean {
  if (dados.criterios.length > 0) {
    const possuiCriteriosPorPostoGraduacao = checaPorPostoGraduacao(dados);
    const possuiCriteriosPorMatricula = checaPorMatricula(dados);
    if (possuiCriteriosPorPostoGraduacao && possuiCriteriosPorMatricula) {
      return true;
    }
    return false;
  }
  return true;
}
