import React, { useMemo } from 'react';
import {
  MdEdit,
  MdHelp,
  MdSearch,
  MdDashboard,
  MdCheck,
  MdList,
} from 'react-icons/md';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
} from '@chakra-ui/react';

import { useAuth } from 'contexts/auth';
import Logo from './Logo';
import MenuItem from './Menu';
import MenuDropdown from './MenuDropdown';

interface ISideBarProps {
  isOpen: boolean;
  onClose(): void;
}

const SideBarMobile: React.FC<ISideBarProps> = ({ isOpen, onClose }) => {
  const { user } = useAuth();
  const perfilComissao = useMemo(() => {
    return user.currentPerfil === 'SGI - COMISSAO';
  }, [user]);

  const perfilAdmin = useMemo(() => {
    return user.currentPerfil === 'SGI - ADM';
  }, [user]);

  const perfilComum = useMemo(() => {
    return user.currentPerfil === 'USUÁRIO PADRÃO';
  }, [user]);
  return (
    <Drawer
      placement="left"
      onClose={onClose}
      isOpen={isOpen}
      size="xs"
      isFullHeight
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <HStack justifyContent="center">
              <DrawerCloseButton />
              <Logo />
            </HStack>
          </DrawerHeader>
          <DrawerBody bg="green.500" p={0}>
            <MenuItem
              to="/home"
              label="INICIAL"
              icon={MdDashboard}
              onClose={onClose}
            />

            <MenuItem
              to="/inscricoes"
              label="MINHAS INSCRIÇÕES"
              icon={MdEdit}
              onClose={onClose}
            />

            {(perfilComissao || perfilAdmin) && (
              <>
                <MenuItem
                  to="/validacoes"
                  label="VALIDAR"
                  icon={MdCheck}
                  onClose={onClose}
                />
                <MenuItem
                  to="/lista"
                  label="LISTA"
                  icon={MdList}
                  onClose={onClose}
                />
              </>
            )}

            {/* <MenuItem
              toExternal={`${process.env.REACT_APP_URL_API}/files/adba51c1-2cd5-4289-9995-12c5452362ea.pdf`}
              label="MANUAL"
              icon={MdHelp}
              onClose={onClose}
            /> */}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default SideBarMobile;
