import React, { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import { format } from 'date-fns';
import { Table } from './styles';

interface ITurma {
  data_inicio_inscricao: string;
  data_fim_inscricao: string;
  titulo: string;
}

interface IProps {
  inscricoes: ITurma[];
}

const TableEncerradas: React.FC<IProps> = ({ inscricoes }) => {
  const formattedDate = useCallback((data: string) => {
    return format(new Date(data), 'dd/MM/yyyy');
  }, []);

  return (
    <Box
      overflowX="auto"
      // overflowY="hidden"
      whiteSpace="nowrap"
      maxWidth="100%"
      css={{
        '&::-webkit-scrollbar': {
          width: '4px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#ccc',
          borderRadius: '24px',
        },
      }}
    >
      <Box maxWidth={{ sm: '280px', md: '100%', lg: '100%', xl: '100%' }}>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
              <th>Data Início Inscrição</th>
              <th>Data Fim Inscrição</th>
            </tr>
          </thead>
          <tbody>
            {inscricoes && inscricoes.length > 0 ? (
              inscricoes.map((inscricao, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{inscricao.titulo}</td>
                  <td>{formattedDate(inscricao.data_inicio_inscricao)}</td>
                  <td>{formattedDate(inscricao.data_fim_inscricao)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>Não há registros no sistema.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default TableEncerradas;
