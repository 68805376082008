import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Checkbox,
  Center,
} from '@chakra-ui/react';
import { useTurma } from 'contexts/turma';
import { useHistory } from 'react-router-dom';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  dados: any;
  turma: {
    titulo: string;
    tipo_turma: {
      tipo: string;
      nome: string;
    };
  };
}

const ModalConfirmaInscricao: React.FC<IProps> = ({
  isOpen,
  onClose,
  dados,
  turma,
}) => {
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { createInscricao } = useTurma();
  const history = useHistory();

  const criarInscricao = async (): Promise<void> => {
    setIsLoading(true);
    const inscricaoCriada = await createInscricao(dados);
    setIsLoading(false);
    onClose();

    if (inscricaoCriada) {
      history.push('inscricao');
    }
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {turma?.tipo_turma.tipo === 'consulta'
            ? 'Confirmar envio?'
            : 'Confirmar Inscrição?'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {turma?.tipo_turma.tipo === 'consulta'
            ? 'Você deseja enviar sua resposta a consulta?'
            : `Você deseja confirmar a sua inscrição para o ${turma.tipo_turma.nome}-${turma.titulo}?`}
          <Center mt="16px">
            <Checkbox
              isChecked={checked}
              onClick={(e: any) => setChecked(e.target.checked)}
            >
              {turma?.tipo_turma.tipo === 'consulta'
                ? 'Declaro que todos os dados foram preenchidos corretamente'
                : 'Declaro que as informações e documentos informados são verdadeiros'}
            </Checkbox>
          </Center>
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={!checked}
            colorScheme="primary"
            mr={3}
            onClick={criarInscricao}
            isLoading={isLoading}
          >
            {turma?.tipo_turma.tipo === 'consulta'
              ? 'Confirmar envio'
              : 'Confirmar Inscrição'}
          </Button>
          <Button onClick={onClose}>Voltar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalConfirmaInscricao;
