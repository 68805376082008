import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import { useTurma } from 'contexts/turma';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  id_inscricao: number;
}

const ModalRevalidarInscricao: React.FC<IProps> = ({
  isOpen,
  onClose,
  id_inscricao,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { revalidarInscricao } = useTurma();

  const handleClickRevalidarInscricao = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await revalidarInscricao(id_inscricao);
      onClose();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmar revalidar inscrição?</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          Você deseja confirmar a revalidação da inscrição?
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="primary"
            mr={3}
            onClick={handleClickRevalidarInscricao}
            isLoading={isLoading}
          >
            Sim
          </Button>
          <Button onClick={onClose}>Voltar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalRevalidarInscricao;
