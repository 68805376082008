import React, { useMemo } from 'react';

import { format } from 'date-fns';

import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useTurma } from 'contexts/turma';
import BoxContent from '../../components/BoxContent';
import TituloPagina from '../../components/TituloPagina';
import Accordion from '../../components/Accordion';
import ModalDeferir from './ModalDeferir';
import ModalIndeferir from './ModalIndeferir';
import TableAnexos from './TableAnexos';

const Label: React.FC = ({ children }) => {
  return (
    <Text fontWeight="600" marginBottom="1px">
      {children}
    </Text>
  );
};

const Validacao: React.FC = () => {
  const { turma, inscricao } = useTurma();
  const {
    isOpen: isOpenDeferir,
    onOpen: onOpenDeferir,
    onClose: onCloseDeferir,
  } = useDisclosure();

  const {
    isOpen: isOpenIndeferir,
    onOpen: onOpenIndeferir,
    onClose: onCloseIndeferir,
  } = useDisclosure();

  const formattedDate = useMemo(() => {
    if (inscricao) {
      return format(
        new Date(inscricao?.data_inscricao),
        "dd/MM/yyyy', às ' HH'h':mm'min'",
      );
    }
    return '';
  }, [inscricao]);

  const statusInscricao = useMemo(() => {
    switch (inscricao?.status) {
      case 'pendente':
        return 'INSCRITO - AGUARDANDO VALIDAÇÃO';
      case 'deferido':
        return 'DEFERIDO';
      case 'indeferido':
        return 'INDEFERIDO';

      default:
        return 'error';
    }
  }, [inscricao]);

  const documentosFormatados = useMemo(() => {
    if (inscricao) {
      const docs = inscricao ? inscricao.documentos : [];
      const docsTodos = turma ? turma.documentos : [];
      return docsTodos.map((doc) => {
        const documentoFind = docs.find(
          (d) => d.id_documento_turma === doc.id_documento_turma,
        );
        if (documentoFind) {
          return { ...documentoFind };
        }
        return {
          id_documento_turma: doc.id_documento_turma,
          id_inscricao: inscricao.id_inscricao,
          id_documento_inscricao: null,
          valido: null,
          motivo_invalido: null,
          filename: null,
          ext: null,
          documento: {
            nome: doc.nome,
          },
          dispensado: true,
        };
      });
    }
    return [];
  }, [inscricao, turma]);

  const ativarBotoes = useMemo(() => {
    const validados = documentosFormatados.filter((df) => df.valido !== null);

    return documentosFormatados.length === validados.length;
  }, [documentosFormatados]);

  const Deferir = useMemo(() => {
    const validos = documentosFormatados.filter((df) => df.valido === true);

    return documentosFormatados.length === validos.length;
  }, [documentosFormatados]);

  const Indeferir = useMemo(() => {
    const validos = documentosFormatados.filter((df) => df.valido === false);

    return validos.length > 0;
  }, [documentosFormatados]);

  return (
    <>
      <TituloPagina
        title={`Validação - ${inscricao?.pessoa.graduacao.gra_sigla} ${inscricao?.pessoa.pessoa.pes_nome}`}
      />

      <BoxContent>
        <>
          {inscricao && (
            <>
              {console.log('inscricoes: ', inscricao)}

              <Accordion defaultIndex={[0]} label="Dados da Inscrição" mt="2">
                <Flex direction="column" fontSize="0.90rem">
                  <Flex direction="row">
                    <Text fontWeight="600" mr="8px">
                      Número de Inscrição:
                    </Text>
                    <Text>{inscricao.numero_inscricao}</Text>
                  </Flex>
                  <Flex direction="row">
                    <Text fontWeight="600" mr="8px">
                      Data da Inscrição:
                    </Text>

                    <Text>{formattedDate}</Text>
                  </Flex>
                  <Flex direction="row">
                    <Text fontWeight="600" mr="8px">
                      Validação:
                    </Text>
                    <Text>{statusInscricao}</Text>
                  </Flex>
                  {inscricao && inscricao.turma.locais_provas.length > 0 && (
                    <Flex direction="row">
                      <Text fontWeight="600" mr="8px">
                        Polo selecionado:
                      </Text>
                      <Text>{inscricao.local_prova}</Text>
                    </Flex>
                  )}
                </Flex>
              </Accordion>
            </>
          )}

          <Accordion defaultIndex={[0]} label="Dados do PM" mt="2">
            <Box display="flex" flexDirection="column" fontSize="0.90rem">
              <Box>
                <Label>Nome:</Label>
                <span>{inscricao?.pessoa.pessoa.pes_nome}</span>
              </Box>

              <Box>
                <Label>Posto/Grad.:</Label>
                <span>{inscricao?.pessoa.graduacao.gra_nome}</span>
              </Box>
              <Flex
                direction={{
                  sm: 'column',
                  md: 'row',
                  lg: 'row',
                  xl: 'row',
                }}
              >
                {inscricao && inscricao.pessoa.graduacao.gra_codigo >= 12 && (
                  <Box marginRight="16px">
                    <Label>Número:</Label>
                    <span>{inscricao.pessoa.pm_numero}</span>
                  </Box>
                )}
                <Box>
                  <Label>Matrícula:</Label>
                  <span>{inscricao?.pes_codigo}</span>
                </Box>
              </Flex>
              <Flex
                justify="space-between"
                direction={{ sm: 'column', md: 'row' }}
              >
                <Box>
                  <Label>Endereço:</Label>
                  <span>{inscricao?.endereco}</span>
                </Box>
                <Box>
                  <Label>Bairro:</Label>
                  <span>{inscricao?.bairro}</span>
                </Box>
                <Box>
                  <Label>Cidade:</Label>
                  <span>{inscricao?.cidade}</span>
                </Box>
                <Box>
                  <Label>Estado:</Label>
                  <span>{inscricao?.uf}</span>
                </Box>
                <Box>
                  <Label>CEP:</Label>
                  <span>{inscricao?.cep}</span>
                </Box>
              </Flex>
              <Flex direction={{ sm: 'column', md: 'row' }}>
                <Box>
                  <Label>Email:</Label>
                  <span>{inscricao?.email}</span>
                </Box>
                <Box ml={{ md: '24px' }}>
                  <Label>Telefone:</Label>
                  <span>{inscricao?.telefone}</span>
                </Box>
              </Flex>
              <Flex>
                <Box>
                  <Label>OPM:</Label>
                  <span>{`${inscricao?.pessoa.opm.uni_nome}-${inscricao?.pessoa.opm.uni_sigla}`}</span>
                </Box>
              </Flex>
            </Box>
          </Accordion>
          {inscricao && inscricao.documentos.length > 0 && (
            <Accordion defaultIndex={[0]} label="Documentos Exigidos" mt="2">
              <TableAnexos documentos={documentosFormatados || []} />
            </Accordion>
          )}
          {inscricao?.status === 'pendente' && (
            <Flex mt="16px" direction="row" justifyContent="center">
              <Button
                size="sm"
                colorScheme="red"
                isDisabled={!ativarBotoes || !Indeferir}
                onClick={() => {
                  onOpenIndeferir();
                }}
              >
                Indeferir
              </Button>
              <Button
                ml="8px"
                size="sm"
                colorScheme="green"
                isDisabled={!ativarBotoes || !Deferir}
                onClick={() => {
                  onOpenDeferir();
                }}

                // isDisabled={!prontoEnvio || inscrito}
              >
                Deferir
              </Button>
            </Flex>
          )}

          {inscricao && (
            <>
              <ModalDeferir
                isOpen={isOpenDeferir}
                onClose={onCloseDeferir}
                id_inscricao={inscricao.id_inscricao}
              />
              <ModalIndeferir
                isOpen={isOpenIndeferir}
                onClose={onCloseIndeferir}
                id_inscricao={inscricao.id_inscricao}
              />
            </>
          )}
        </>
      </BoxContent>
    </>
  );
};

export default Validacao;
