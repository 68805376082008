import React, { useCallback, useRef, useState } from 'react';
import { MdHelp, MdSearch } from 'react-icons/md';
import { FiUploadCloud } from 'react-icons/fi';
import {
  Badge,
  Box,
  Checkbox,
  HStack,
  Input,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useAuth } from 'contexts/auth';

import { useTurma } from 'contexts/turma';
import ModalMotivoDispensa from '../ModalMotivoDispensa';

import { Table } from './styles';

interface IDocumento {
  id_documento_turma: number;
  nome: string;
  slug: string;
  dispensavel: boolean;
  dispensavel_motivo: string | null;
  tipo_upload: string;
}

interface IProps {
  documentos: IDocumento[];
}

const AnexosTable: React.FC<IProps> = ({ documentos }) => {
  const { user } = useAuth();
  const toast = useToast();
  const {
    turma,
    adicionaUpload,
    uploads,
    adicionaDispensa,
    removeDispensa,
    removeUpload,
    dispensas,
  } = useTurma();
  const inputFile = useRef<HTMLInputElement[]>([]);

  const {
    isOpen: isOpenMotivoDispensa,
    onOpen: onOpenMotivoDispensa,
    onClose: onCloseMotivoDispensa,
  } = useDisclosure();

  const [motivoAtual, setMotivoAtual] = useState('Não especificado');

  const adicionaDocumento = useCallback(
    (index: number, slug: string, id_documento_turma: number) => {
      const files = inputFile.current[index]?.files;

      if (files && files[0] && files[0].size <= 2097152) {
        if (files.length > 0) {
          const file = files[0];

          const parts = file.name.split('.');
          const [ext] = parts.slice(-1);

          const filename = `${turma?.id_turma}-${slug}-${user.matricula}`;
          const myNewFile = new File([file], `${filename}.${ext}`, {
            type: file.type,
          });

          const hash_md5 = 'wsdfsd9f876s87f6sdf';
          adicionaUpload({
            file: myNewFile,
            id_documento_turma,
            index,
            filename,
            hash_md5,
            ext,
          });
        }
      } else {
        toast({
          title: 'Tamanho excedido',
          description: 'O arquivo deve possuir tamanho máximo de 2MBytes',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [user, adicionaUpload, turma, toast],
  );

  const alteraDispensa = useCallback(
    (value: boolean, index: number, slug: string) => {
      if (value) {
        adicionaDispensa(index, slug);
      } else {
        removeDispensa(index);
        removeUpload(index);
      }
    },
    [removeDispensa, adicionaDispensa, removeUpload],
  );

  const onButtonClick = (index: number): void => {
    if (inputFile.current) {
      inputFile.current[index].click();
    }
  };

  const uploadCompleto = useCallback(
    (index: number): boolean => {
      const indexes = uploads.map((up) => up.index);
      return indexes.includes(index);
    },
    [uploads],
  );

  const uploadDesativado = useCallback(
    (index: number): boolean => {
      const indexes = dispensas.map((up) => up.index);
      return indexes.includes(index);
    },
    [dispensas],
  );

  const tipoUpload = useCallback((tipo_upload: string): {
    label: string;
    value: string;
  } => {
    switch (tipo_upload) {
      case '1':
        return { label: 'PDF', value: '.pdf' };
      case '2':
        return { label: 'IMAGEM', value: '.jpg, .jpeg, .png' };
      case '3':
        return { label: 'PDF/IMAGE', value: '.jpg, .jpeg, .png, .pdf' };

      default:
        return { label: 'PDF/IMAGE', value: '.jpg, .jpeg, .png, .pdf' };
    }
  }, []);

  const handleClickMotivo = (texto: string | null): void => {
    const motivo = texto || 'Não especificado';
    setMotivoAtual(motivo);
    onOpenMotivoDispensa();
  };

  return (
    <Box
      overflowX="auto"
      overflowY="hidden"
      whiteSpace="nowrap"
      maxWidth="100%"
      css={{
        '&::-webkit-scrollbar': {
          width: '4px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#ccc',
          borderRadius: '24px',
        },
      }}
    >
      <Box maxWidth={{ sm: '280px', md: '100%', lg: '100%', xl: '100%' }}>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Documento</th>
              <th>Dispensado?</th>
              <th className="actions" style={{ textAlign: 'center' }}>
                Upload
              </th>
            </tr>
          </thead>
          <tbody>
            {documentos &&
              documentos.map((documento, index) => (
                <tr key={documento.id_documento_turma}>
                  <td>{index + 1}</td>
                  <td>
                    {documento.nome}{' '}
                    <Badge>{tipoUpload(documento.tipo_upload).label}</Badge>
                  </td>
                  <td>
                    {documento.dispensavel && (
                      <HStack>
                        <Checkbox
                          onClick={(e: any) =>
                            alteraDispensa(
                              e.target.checked,
                              index,
                              documento.slug,
                            )
                          }
                        >
                          Sim
                        </Checkbox>

                        <MdHelp
                          size={20}
                          color="#3182ce"
                          cursor="pointer"
                          onClick={() =>
                            handleClickMotivo(documento.dispensavel_motivo)
                          }
                        />
                      </HStack>
                    )}
                  </td>

                  <td className="actions">
                    <HStack>
                      <>
                        <>
                          <Input
                            type="file"
                            id={`file${index}`}
                            name={documento.slug}
                            ref={(el) => {
                              if (el) {
                                inputFile.current[index] = el;
                              }
                            }}
                            accept={tipoUpload(documento.tipo_upload).value}
                            size="sm"
                            style={{ display: 'none' }}
                            onChange={() =>
                              adicionaDocumento(
                                index,
                                documento.slug,
                                documento.id_documento_turma,
                              )
                            }
                          />
                          <Tooltip
                            label="Enviar Arquivo"
                            hasArrow
                            placement="left"
                          >
                            <Box>
                              <FiUploadCloud
                                size={20}
                                color={
                                  uploadCompleto(index) &&
                                  !uploadDesativado(index)
                                    ? 'green'
                                    : 'black'
                                }
                                onClick={() => {
                                  if (!uploadDesativado(index)) {
                                    onButtonClick(index);
                                  }
                                }}
                                cursor="pointer"
                              />
                            </Box>
                          </Tooltip>
                        </>

                        {uploadCompleto(index) && (
                          <>
                            <MdSearch
                              size={20}
                              color="green"
                              cursor="pointer"
                              onClick={() => {
                                const files = inputFile.current[index]?.files;
                                if (files) {
                                  const url = window.URL.createObjectURL(
                                    files[0],
                                  );

                                  window.open(url);
                                }
                              }}
                            />
                          </>
                        )}
                      </>
                    </HStack>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        <ModalMotivoDispensa
          isOpen={isOpenMotivoDispensa}
          onClose={onCloseMotivoDispensa}
          texto={motivoAtual}
        />
      </Box>
    </Box>
  );
};

export default AnexosTable;
