import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Textarea,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTurma } from 'contexts/turma';

const schema = Yup.object().shape({
  valido: Yup.string().required('Um opção deve ser selecionada!'),
  motivo_invalido: Yup.string().when('valido', {
    is: (value) => value === 'nao',
    then: Yup.string().required(
      'Um motivo é requerido quando o arquivo é inválido',
    ),
    otherwise: Yup.string(),
  }),
});

interface IFormInputs {
  valido: string;
  motivo_invalido: string;
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  id_documento_inscricao: number;
}

const ModalAdicionarEmail: React.FC<IProps> = ({
  isOpen,
  onClose,
  id_documento_inscricao,
}) => {
  const { validaDocumento } = useTurma();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = async (data: any): Promise<void> => {
    setIsSubmitting(true);
    try {
      const dadosFormatados = {
        id_documento_inscricao,
        valido: data.valido === 'sim',
        motivo_invalido: data.motivo_invalido,
      };
      await validaDocumento(dadosFormatados);
      setIsSubmitting(false);
      onClose();
    } catch (error) {
      setIsSubmitting(false);
      onClose();
    }
  };

  const { handleSubmit, errors, register, watch } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      valido: undefined,
      motivo_invalido: '',
    },
  });

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Validar Documento</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form>
            <Flex direction="column">
              <FormControl id="valido" isRequired isInvalid={!!errors.valido}>
                <FormLabel fontSize="0.90rem">Válido?</FormLabel>

                <Select
                  fontSize="0.90rem"
                  name="valido"
                  ref={register}
                  placeholder="Selecione uma opção!"
                >
                  <option value="sim">SIM</option>
                  <option value="nao">NÃO</option>
                </Select>
                <FormErrorMessage>{errors.valido?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                mt="8px"
                id="motivo_invalido"
                isRequired
                isInvalid={!!errors.motivo_invalido}
              >
                <FormLabel fontSize="0.90rem">Motivo Inválido</FormLabel>

                <Textarea
                  name="motivo_invalido"
                  ref={register}
                  placeholder="Digite o motivo"
                  size="sm"
                  isDisabled={watch('valido') !== 'nao'}
                />
                <FormErrorMessage>
                  {errors.motivo_invalido?.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="primary"
            mr={3}
            onClick={handleSubmit((data) => onSubmit(data))}
            isLoading={isSubmitting}
          >
            Salvar
          </Button>
          <Button colorScheme="red" onClick={onClose}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAdicionarEmail;
